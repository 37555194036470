// COMPONENT.HERO-NATURE-DETAILS

$hero-bp: "large";

.c-hero-nature-detail {
	position: relative;
	background-color: $grey--lightest;
	margin-bottom: 0;

	@include bp("medium") {
		margin-bottom: $bsu;
	}
}

.c-hero__primary {
	.c-hero-nature-detail & {
		background-color: $grey--lightest;
		background-image: url("/content/images/interface/bg/white-circles-pattern-quarter-left.svg");
		background-position: bottom left;
		background-repeat: no-repeat;
		margin-bottom: 0;


		@include bp-below("large") {
			background-size: 200px;
			flex-direction: column;
		}
		@include bp("large") {
			margin-bottom: 0;
		}
	}
}

.c-hero__primary .c-hero__body {
	.c-hero-nature-detail & {
		margin-bottom: 0;

		@include bp-below("large") {
			padding: 0;
		}
		@include bp("large") {
			margin-bottom: 0;
		}
	}
}

.c-hero__primary .c-hero__figure {
	.c-hero-nature-detail & {
		@include bp("large") {
			height: 100vh;
			max-height: 740px;
			overflow: hidden;
		}
	}
}

.c-hero-nature-detail__meta {
	@include font-base-14-tall;
	display: flex;
	align-items: center;

	@include bp("large") {
		@include font-base-20--tall;
		margin-bottom: $ssu;
	}
}

.c-hero-nature-detail__title {
	@include font-heading-28b;

	@include bp("large") {
		@include font-heading-40b;
		margin-bottom: $bsu;
	}

	@include bp("extra-large") {
		@include font-heading-52b;
	}
}

.c-hero-nature-detail__intro {
	@include bp-below("large") {
		display: none;
	}
	@include bp("large") {
		@include font-base-20;
		line-height: lh(32, 20);
	}

	a {
		@include link-brand;
		@include font-base-20sb;
	}
}

.c-hero-nature-detail__subnav-layout {
	width: 100%;
	background-color: $white;
	margin-bottom: $bsu;

	@include bp("large") {
		margin-bottom: $bsu;
	}
}

.c-hero-nature-detail__subnav-wrapper {
	width: 50%;



	@include bp-below("medium") {
		width: auto;
		//max-width: 330px;
	}
}

.c-hero-nature-detail__subnav-container {
	margin-top: -150px;

	@include bp-below($layout-wrapper-max-width) {
		padding-left: $bsu;
	}

	@include bp-below("large") {
		padding-left: 0;
	}

	@include layout-split(left, 50%);
	padding-right: $bsu;
}


.c-hero-nature-detail__subnav {
	width: 100%;
	position: relative;
	z-index: 1;
	background-color: $white;
	//padding: $ssu;
	border-left: 2px solid $secondary-a;

	@include bp-below("large") {
		z-index: 2;
	}
}

.c-hero-nature-detail__subnav-title {
	@include font-base-12-tall;
	padding: $ssu;

	@include bp("large") {
		@include font-base-14-tall;
		padding: $ssu $bsu $tsu;
	}
}

.c-hero-nature-detail__subnav-items {
	@include list-reset;
}

.c-hero-nature-detail__subnav-item {}

.c-hero-nature-detail__subnav-item-link {
	@include font-heading-16b;
	display: block;
	padding: ($tsu /2) $ssu;
	color: $primary-a;

	[aria-current="page"] & {
		color: $secondary-a;

		&:hover,
		&:focus {
			background-color: $secondary-a;
			color: $secondary-b;
		}
	}

	&:hover,
	&:focus {
		background-color: $secondary-a;
		color: $secondary-b;
	}

	@include bp("large") {
		@include font-heading-20b;
		padding: $tsu $bsu;
	}
}

.c-hero__sticky-subnav {
	display: none;
	background-color: $grey--lightest;
	border-left: 2px solid $secondary-a;
	width: 100%;

	&.is-sticky {
		@include bp-below("large") {
			display: block;
			position: fixed;
			top: 0;
			left: 0;
		}
	}
}

.c-hero__sticky-subnav-toggle {
	display: flex;
	width: 100%;
	border: 0;
	background-color: $white;
	@include font-heading-16b;
	color: $primary-a;
	justify-content: space-between;
	align-items: center;
	height: 60px;

	&::after {
		@include font-icon;
		content: $iconf-arrow-down-chevron;
		display: inline-block;
		margin-left: $ssu;
		font-size: 20px;
		width: 60px;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $grey--lightest;
		position:absolute;
		right:0;
	}

	&[aria-expanded="true"] {

		&::after {
			content: $iconf-arrow-up-chevron;
		}
	}
}

.c-hero__sticky-subnav-toggle-text {
	padding: $tsu $ssu;
}

.c-hero__sticky-subnav-expand {
	display: none;
	width: calc(100% - 60px);
	background-color: $white;

	&[aria-expanded="true"] {
		display: block;
	}
}

