// OBJECT.BODY-TEXT

// First class here targets the RTE within the CMS so it will also get styled
.mce-content-body {
	padding: 10px !important;
}

@mixin body-text {
	@include clearfix;

	> *:first-child {
		margin-top: 0;
	}

	> *:last-child {
		margin-bottom: 0;
	}

	> p {
		@include font-body-text;
		margin-bottom: 1.5em;
	}

	a {
		@include link-brand;
		@include font-body-link-text;
	}

	h2,
	h3,
	h4,
	h5 {
		margin: 1.2em 0 0.6em;
	}

	h2 {

		@include font-heading-24b;

		@include bp("medium") {
			@include font-heading-30b;
		}

		@include bp("large") {
			@include font-heading-36b;
		}
	}

	h3 {
		@include font-heading-18b;

		@include bp("medium") {
			@include font-heading-24b;
		}

		@include bp("large") {
			@include font-heading-28b;
		}
	}

	h4 {
		@include font-heading-16b;

		@include bp("medium") {
			@include font-heading-18b;
		}

		@include bp("large") {
			@include font-heading-20b;
		}
	}

	h5 {
		@include font-heading-16b;

		@include bp("large") {
			@include font-heading-18b;
		}
	}

	.green {
		color: var(--secondary-a);

		// This format needs to override the font size
		@include font-base-16;

		@include bp("large") {
			@include font-base-20;
		}
	}

	.disclaimer {
		font-style: italic;
		font-size: 0.8em;
	}

	hr {
		border-width: 1px 0 0;
		border-top: 1px solid $grey;
		height: 0;
		clear: both;
		margin: 3em 0;
	}

	> ul > li {
		list-style-type: disc;
	}

	> ol > li {
		list-style-type: decimal;
	}

	> ul,
	> ol {
		padding: 0 0 0 1em;
		margin: 1.5em 0;

		li {
			@include font-body-text;
			margin-bottom: 0.5em;
		}

		&[style*="list-style-type:"] {
			> li {
				list-style-type: inherit;
			}
		}

		ul,
		ol {
			margin: 0.25em 0;
		}

		ul {
			li {
				list-style-type: circle;
			}

			&[style*="list-style-type:"] li {
				list-style-type: inherit;
			}
		}

		ol {
			li {
				list-style-type: lower-roman;
			}

			&[style*="list-style-type:"] li {
				list-style-type: inherit;
			}
		}
	}

	table {
		width: 100%;
		min-width: 480px;
		clear: both;
		margin-bottom: $bsu;
		border-collapse: collapse;

		p {
			margin: 0;
		}
		// Remove default min-width if content editor has set the width in the CMS
		&[style*="width"] {
			min-width: 0;
		}
		// Add margin to right if content editor has set the table to float left in the CMS
		&[style*="float: left"] {
			margin-right: $bsu;
		}
		// Add margin to left if content editor has set the table to float right in the CMS
		&[style*="float: right"] {
			margin-left: $bsu;
		}
	}

	.tablescroll table {
		margin-bottom: 0;
	}

	th {
		@include font-base-16;
		background-color: var(--primary-a);
		color: var(--primary-b);
	}

	thead {

		td {
			@include font-base-16;
			background-color: var(--primary-a);
			color: var(--primary-b);
		}
	}

	th,
	td {
		@include ui-border('all');
		padding: $tsu $ssu;
	}

	td {
		@include font-base-16;
		vertical-align: top;
	}

	caption {
		@include ui-border(bottom);
		@include font-base-16;
		caption-side: bottom;
		padding: $bsu 0;
		text-align: left;
	}

	tr {
		&:nth-child(even) {

			td {
				background-color: $grey--lightest;
			}
		}
	}
}

// RTE figure - shared styling across macros
@mixin body-text__figure {
	margin: $bsu auto;
	clear: both;

	&.u-float--left {
		clear: left;

		@include bp("small") {
			margin: 0 $bsu $ssu 0;
		}
	}

	&.u-float--right {
		clear: right;

		@include bp("small") {
			margin: 0 0 $ssu $bsu;
		}
	}
}

@mixin body-text__figcaption {
	@include ui-border("bottom");
	@include font-base-16;
	padding: $ssu 0
}

.mce-content-body {
	@include body-text;
}
