﻿// CONTROL.FAQ

.c-faq {
	@include control;
}

.c-faq__layout {
	@include layout-wrapper;
}

.c-faq__heading {
	@include control__heading;
}

.c-faq__list {
	@include list-reset;
	@include list-stacked;
}

.c-faq__item {
	//@include ui-border("all");
	margin-bottom: $tsu;
	box-shadow: none;
	transition-property: box-shadow, border;
	transition-duration: 0.25s;
	transition-timing-function: ease-in-out;
	background-color: $grey--lightest;

	&[aria-expanded="false"] {
		&:hover,
		&:focus {
			background-color: $brand;
		}
	}
}

.c-faq__item-header {
	//@include link-transition;
	position: relative;
	cursor: pointer;

	&:focus:not(:focus-visible) {
		outline: none;
		color: $brand;
	}

	&[aria-expanded="false"] {
		&:hover{
			color: $white;
		}
	}

	&:hover,
	&:focus {
		color: $brand;
	}
}

.c-faq__item-heading {
	@include font-heading-20b;
	padding: ($tsu * 1.5) ($ssu * 1.5);
	padding-right: $bsu * 3;
}

.c-faq__item-icon {
	position: absolute;
	top: 50%;
	right: $bsu;
	font-size: 20px;
	transform: translateY(-50%);
	transition: transform 0.3s linear;
	pointer-events: none;

	&::before {
		@include font-icon;
		content: $iconf-plus;
		color: var(--link--hover);
	}

	.c-faq__item.is-expanded &,
	.c-faq__item[aria-expanded="true"] & {
		&::before {
			content: $iconf-minus;
		}
	}
}

.c-faq__item-body {
	padding: $ssu $bsu;

	.js & {
		display: none;
	}

	.c-faq__item.is-expanded &,
	.c-faq__item[aria-expanded="true"] & {
		display: block;
	}
}

.c-faq__item-text {
	@include body-text;

	a {
		color: var(--link);
		text-decoration-line: underline;
		text-decoration-color: var(--link--hover);
		@include font-body-link-text;

		&:hover,
		&:focus {
			color: var(--link--hover);
		}
	}
}
