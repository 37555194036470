// COMPONENT.CED-PAGE-FILTER

$ced-page-filter-bp: "medium";

.c-ced-page-filter {
	width: 100%;
	padding: $ssu $ssu 0;

	position: relative;

	@include bp-below($ced-page-filter-bp) {
		&:nth-child(n+2) {
			border-top: 1px solid $grey--dark;
		}
	}
	@include bp($ced-page-filter-bp) {
		width: 50%;
		&:first-child:last-child {
			width: 100%;
		}

		padding: $bsu $bsu $ssu;

		margin-top: -80px;

		&:first-child {
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
		}
		&:last-child {
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}
		&:nth-child(2) {
			border-left: 1px solid $grey--dark;
		}
	}
}

.c-ced-page-filter__background {
	background: $grey--light;
}

.c-ced-page-filter__list {
	display: flex;
	@include bp-below($ced-page-filter-bp) {
		flex-wrap: wrap;
	}
	@include bp($ced-page-filter-bp) {
		@include layout-wrapper;
	}
}

.c-ced-page-filter__title {
	@include bp-below("large") {
		@include font-base-12mu;
	}
	@include bp("large") {
		@include font-base-16u;
	}
}

.c-ced-page-filter__cover {
	@include button-reset;
	text-align: left;

	display: flex;
	justify-content: space-between;
	width: 100%;

	padding: $ssu 0;
	&::after {
		@include font-icon;
		content: $iconf-arrow-down-chevron;

		color: var(--supporting-2-a);
	}

	@include bp-below("large") {
		@include font-heading-20b;
	}
	@include bp("large") {
		@include font-heading-28b;
	}
}

.c-ced-page-filter__option-list {
	@include list-reset;

	display: none;
	.c-ced-page-filter[aria-expanded="true"] & {
		display: block;
	}

	position: absolute;
	z-index: 1;
	left: 0;
	width: 100%;
	top: 100%;

	background: $white;
	background: var(--primary-b);
	box-shadow: 0 0 2px rgba($black, 0.2);
	padding: $ssu $bsu;
}

.c-ced-page-filter__option-item {
}

.c-ced-page-filter__option {
	display: block;

	@include font-base-18u;

	color: $brand;
	color: var(--primary-a);

	&:hover,
	&:focus {
		color: var(--supporting-2-a);
	}

	@include link-transition;

	padding: $tsu 0;
	border-bottom: 1px solid $grey--light;
	.c-ced-page-filter__option-item:last-child & {
		border-bottom: none;
	}
}
