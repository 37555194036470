﻿// CONTROL.CTA

.c-cta {
	@include control;
}

.c-cta__layout {
	@include layout-wrapper;
}

.c-cta--dark {
	.c-cta__body {
		background: $grey--darkest;
		background: var(--primary-a);
	}
	.c-cta__heading,
	.c-cta__text {
		color: $white;
		color: var(--primary-b);
	}
}

.c-cta__body {
	position: relative;
	padding: $ssu $ssu $bsu $ssu;
	background-color: $grey--lightest;

	.o-layout-main &,
	.l-page__main-controls &,
	.l-page__secondary-controls & {
		@include bp("medium") {
			@include flex-extend;
			align-items: flex-start;
			padding: $ssu $bsu;
		}
		@include bp("large") {
			align-items: center;
		}
	}

	.c-two-column & {
		@include bp("large") {
			display: block;
			padding: $ssu $ssu $bsu $ssu;

			.c-cta__image {
				max-width: 220px;
				margin: $ssu auto $bsu;
			}
		}
	}

	.l-page__sidebar & {
		.c-cta__image {
			max-width: 220px;
			margin: $ssu auto $bsu;
		}

		.c-cta__text-content {
			display: block;
			text-align: center;
		}

		.c-cta__link {
			margin-top: $ssu;
		}
	}
}

.c-cta__image {
	background-color: transparent !important;

	&, img {
		display: block;
	}
	flex-shrink: 0;

	max-width: 220px;
	margin: $ssu auto $bsu;

	@include bp("medium") {
		margin: 0 $bsu 0 0;
		max-width: unquote("min(220px, 25%)");
	}

	.c-cta & {
		// Extra specificity needed to override other styles
		padding-bottom: 0;
		img {
			position: static;
		}
	}
}

.c-cta__text-content {
	@include bp("large") {
		flex-grow: 1;

		display: flex;
		align-items: center;
	}
}

.c-cta__body-wrapper {
	@include bp-below("large") {
		margin-bottom: $bsu;
	}

	.o-layout-main &,
	.l-page__main-controls &,
	.l-page__secondary-controls & {
		@include bp("medium") {
			flex: 1 1 auto;
			margin-bottom: $ssu;
		}

		@include bp("large") {
			margin-bottom: 0;
			padding-right: $lsu;
		}
	}

	.c-two-column & {
		@include bp("large") {
			flex: 1 1 auto;
			margin-bottom: 0;
		}
	}

	> *:last-child {
		margin-bottom: 0;
	}
}

.c-cta__heading {
	@include font-heading-28b;
	margin-bottom: $ssu;
	color: $body-color;
}

.c-cta__text {
	@include font-base-16;
	margin-bottom: 2em;
	color: $body-color;
}

.c-cta__link {
	@include button("medium");

	@include bp("medium") {
		white-space: nowrap;
	}
}