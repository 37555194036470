﻿@use "sass:math";

// CONFIG

// Layout
// Max width of layout wrapper which contains content
// Width of sidebar (default is to float at 'large' breakpoint)
$viewport-max-width: 1920px;

$layout-wrapper-max-width: 1360px;
$layout-aside-width: 280px;
$layout-aside-gutter-width: 60px;
$layout-wrapper-narrow-width: $layout-wrapper-max-width - ($layout-aside-width + $layout-aside-gutter-width);
$layout-wrapper-modal-width: $layout-wrapper-narrow-width;

// Spacings
// $bsu: Base spacing unit
// $tsu: Tiny spacing unit
// $ssu: Small spacing unit
// $lsu: Large spacing unit
// $hsu: Huge spacing unit
$bsu: 40px;
$tsu: ($bsu / 4);
$ssu: ($bsu / 2);
$lsu: $bsu * 2;
$hsu: $bsu * 4;

// Breakpoints
// Suffixes used for namespaced helpers
// Pixel values will be output as em units
$breakpoints: (
	"extra-small" (
		"suffix": "xs",
		"start": 0,
		"end": 479px
	),
	"small" (
		"suffix": "s",
		"start": 480px,
		"end": 767px
	),
	"medium" (
		"suffix": "m",
		"start": 768px,
		"end": 1023px
	),
	"large" (
		"suffix": "l",
		"start": 1024px,
		"end": 1279px
	),
	"extra-large" (
		"suffix": "xl",
		"start": 1280px,
		"end": 9000px
	)
);

// Icons
$icomoon-font-path: '/Content/fonts/font-icons/fonts';

// Columns
// By default we will create wholes, halves, thirds, quarters, and fifths
// Predefine this map to override
$columns: (1, 2, 3, 4, 5);

// Z-Index
// 1
$z-page: 1;

// 2
$z-header: 2;
$z-share: 2;
$z-slick-arrows: 2;

// 3
$z-tooltip: 3;

// 4
$z-back-to-top : 4;

:root {
	--scrollbar-width: 17px;
	--page-width-full: calc(100vw - var(--scrollbar-width));
	--page-width: var(--page-width-full);

	// Layout variables //
	//////////////////////

	--layout-gutter-base-left: #{$bsu};
	--layout-gutter-base-right: #{$bsu};

	@include bp-below("large") {
		--layout-gutter-base-left: #{$ssu};
		--layout-gutter-base-right: #{$ssu};
	}

	--layout-gutter-left: var(--layout-gutter-base-left);
	--layout-gutter-right: var(--layout-gutter-base-right);

	// 0/1 as an on-off switch, to record if a narrow layout wrapper should shift
	--layout-narrow-shift: 1;
}

// Control variables //
///////////////////////

:root {
	// Gap and margin/padding variables are different so
	// the base gaps can still be referred to when the
	// values being used for margin/padding have changed
	--control-gap-top-base: #{$lsu};
	--control-gap-bottom-base: #{$lsu};
	@include bp("extra-large") {
		--control-gap-top-base: #{$hsu};
		--control-gap-bottom-base: #{$hsu};
	}

	--control-gap-top: var(--control-gap-top-base);
	--control-gap-bottom: var(--control-gap-bottom-base);
}

// Special rules for reduced space between a set of controls
.c-rte,
.c-cta,
.c-document-download,
.c-dual-cta,
.c-expand-collapse,
.c-faq,
.c-highlight-text,
.c-pods,
.c-related-items,
.c-two-column,
.c-image,
.c-video,
.c-two-column + .c-guides-listing {
	&:not(:first-child) {
		--control-gap-top: calc(var(--control-gap-top-base) / 2);
		--control-margin-top: var(--control-gap-top);
	}

	&:not(:last-child) {
		--control-gap-bottom: calc(var(--control-gap-bottom-base) / 2);
		--control-margin-bottom: var(--control-gap-bottom);
	}
}

:root {
	--control-margin-top: var(--control-gap-top);
	--control-margin-bottom: var(--control-gap-bottom);
	--control-padding-top: 0;
	--control-padding-bottom: 0;
	// Uses 0 and 1 as an on/off switch via multiplication
	--last-control-was-grey: 0;
	--last-control-was-dark-grey: 0;
	--last-control-was-flush: 0;

	--control-bg-offset: #{$grey--lightest};

	// Filter variables //
	//////////////////////
	--filter-highlight: var(--secondary-a);
}
