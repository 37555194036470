﻿// CONTROL.PODS

$pod-bp: "large";

.c-pods {
	@include control;
}

.c-pods__layout {
	@include layout-wrapper;

	@include bp-below("medium") {
		margin-left: $tsu;
		margin-right: $tsu;
		padding-left: 0;
		padding-right: 0;
	}

	.o-grid-layout {
		@include bp-below("medium") {
			grid-gap: $tsu;
		}
	}

	.o-grid-layout--two {
		grid-template-columns: repeat(2, 1fr);

		@include bp("medium") {
			grid-template-columns: repeat(2, 1fr);
		}

		@include bp-below(375px) {
			grid-template-columns: repeat(1, 1fr);
		}
	}

	.o-grid-layout--three {
		grid-template-columns: repeat(1, 1fr);

		@include bp("medium") {
			grid-template-columns: repeat(3, 1fr);
		}

		@include bp-below(375px) {
			grid-template-columns: repeat(1, 1fr);
		}
	}

	.o-grid-layout--four {
		grid-template-columns: repeat(2, 1fr);

		@include bp("medium") {
			grid-template-columns: repeat(2, 1fr);
		}

		@include bp("large") {
			grid-template-columns: repeat(4, 1fr);
		}

		@include bp-below(375px) {
			grid-template-columns: repeat(1, 1fr);
		}
	}
	// Alternating sets
	.o-grid-layout--two-four-alt {
		grid-template-columns: repeat(2, 1fr);

		> :nth-child(6n + 1),
		> :nth-child(6n + 2) {
			grid-column: span 2;
		}

		@include bp("medium") {
			grid-template-columns: repeat(2, 1fr);
		}

		@include bp("large") {
			grid-template-columns: repeat(4, 1fr);
		}

		@include bp-below(375px) {
			grid-template-columns: repeat(1, 1fr);

			> :nth-child(6n + 1),
			> :nth-child(6n + 2) {
				grid-column: 1;
			}
		}
	}

	.o-grid-layout--four-two-alt {
		grid-template-columns: repeat(2, 1fr);

		@include bp("medium") {
			grid-template-columns: repeat(2, 1fr);
		}

		> :nth-child(6n + 5),
		> :nth-child(6n) {
			grid-column: span 2;
		}


		@include bp("large") {
			grid-template-columns: repeat(4, 1fr);
		}

		@include bp-below(375px) {
			grid-template-columns: repeat(1, 1fr);

			> :nth-child(6n + 5),
			> :nth-child(6n) {
				grid-column: 1;
			}
		}
	}


	.o-grid-layout--one-two-alt {
		grid-template-columns: repeat(2, 1fr);

		> :nth-child(6n + 1) {
			grid-column: span 2;
		}

		> :nth-child(6n) {
			grid-column: span 2;
		}

		@include bp-below(375px) {
			grid-template-columns: repeat(1, 1fr);

			> :nth-child(6n + 1) {
				grid-column: 1;
			}

			> :nth-child(6n) {
				grid-column: 1;
			}
		}

		@include bp("large") {
			grid-template-columns: repeat(4, 1fr);

			> :nth-child(6n + 1) {
				grid-column: span 2;
			}

			> :nth-child(6n) {
				grid-column: span 2;
			}
		}
	}
}

.c-pods__heading {
	@include control__heading;
}

.c-pod,
.c-pod-noimage {
	position: relative;
	display: block;
	height: 360px;

	@include bp("medium") {
		height: 280px;
	}

	@include bp("large") {
		height: 420px;
	}

	@include bp-below(480px) {
		height: 215px;
	}

	&:hover,
	&:focus {
		.c-pod-flip__front .c-pod__heading {
			opacity: 0;
		}

		.c-pod-flip__back {
			opacity: 1;
			transform: rotateY(0);
		}
	}
}

.c-pod__body,
.c-pod-noimage__body {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	//overflow: hidden;
	//padding: 0.666em;
	text-align: center;
	color: $white;
}

.c-pod__heading,
.c-pod-noimage__heading {
	@include font-heading-20b;

	@include bp("large") {
		@include font-heading-28b;
	}
	//position: absolute;
	//left: 50%;
	//top: 50%;
	//width: calc(100% - #{$lsu});
	//transform: translate(-50%, -50%);
	display: flex;
	align-items: flex-end;

	.c-pod-flip__front & {

		&::after {
			visibility: hidden;
			opacity: 0;
		}
	}
}

// IMAGE POD
.c-pod__figure {
	@include figure;
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&:hover,
	&:focus {
		.c-pod__image {
			//transform: translate(-50%, -50%) scale(1.05);
		}
	}
}

.c-pod__image {
	position: absolute;
	left: 50%;
	top: 50%;
	width: auto;
	min-width: 101%;
	min-height: 101%;
	transform: translate(-50%, -50%);
	transition: transform 0.2s ease-in-out;
	// Override o-fit styles
	height: 100%;
	padding-bottom: 0 !important;
}

.c-pod__image--mobile {
	@include bp($pod-bp) {
		display: none;
	}
}

.c-pod__image--desktop {
	@include bp-below($pod-bp) {
		display: none;
	}
}

.c-pod__body {
	//background-color: rgba($black, 0.35);
	transition: background-color 0.2s ease-in-out;
}

.c-pod__figure {
	&:hover,
	&:focus {
		.c-pod__body {
			background-color: transparent;
		}
	}
}

.c-pod__text {
	//max-height: 0;
	//overflow: hidden;
	margin: 0;
	transition: 0.4s ease;
	transition-property: max-height;
}

.c-pod-hover,
.c-pod__figure:focus {
	.c-pod__text {
		max-height: 200px;
	}
}

// NO IMAGE POD
.c-pod-noimage {
	background: $brand;
}

a.c-pod-noimage {
	transition: background-color 0.2s ease-in-out;

	&:hover {
		background: darken($brand, 10);
	}
}

.c-pod-noimage__body {
	//padding: $bsu;
	color: $white;
}


// POD FLIP
.c-pod-flip {
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
	text-align: center;
	perspective: 1000px;
}


.c-pod-flip__inner {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	padding: $tsu;
	-webkit-backface-visibility: hidden; /* Safari */
	backface-visibility: hidden;
	text-align: left;
	transition: all ease .3s;


	@include bp("medium") {
		padding: $ssu + ( $tsu / 2 );
	}

	.c-pod-noimage &,
	.c-pod__body & {
		display: flex;
		justify-content: flex-end;
		height: 100%;
		flex-direction: column;
		align-items: flex-start;
	}
}

.c-pod-flip__front {
	background-color: transparent;
	position: relative;
}

.c-pod-flip__back {
	position: absolute;
	background-color: rgba($primary-a, 0.8);
	opacity: 0;
	transform: rotateY( -90deg);
	transition: .3s;
	transform-style: preserve-3d;
	pointer-events: none;

	&.c-pod-flip__inner {
		justify-content: flex-start;
	}
}


.c-pod__cat {
	@include font-base-12mu;
	position: absolute;
	top: $ssu;

	.c-pod:hover & {
		opacity: 0;
	}
}

.c-pod__tag {
	@include font-base-12mu;
	padding: ($tsu / 2) ($tsu * 1.5);
	text-align: center;
	position: absolute;
	top: 0;
	right: 0;
	display: inline-block;
	background-color: var(--primary-a);
	color: var(--primary-b);
	min-width: 93px;

	.c-pod:hover & {
		opacity: 0;
	}
}

.c-pod__text {
	margin-top: $ssu;
	@include font-base-16;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 10;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.c-pod__icon {
	display: inline-block;
	margin-top: auto;
	margin-left: auto;
	font-size: 25px;
}
