// CONTROL.GUIDES-LISTING

.c-guides-listing {
	@include control;

	.l-page__related-guides-controls & {
		@include bp("large") {
			margin-top: 0;
		}
	}
}

.c-guides-listing__layout {
	@include layout-wrapper;
}

.c-guides-listing__intro {
	margin-bottom: $ssu;

	@include bp("medium") {
		width: 70%;
		margin-bottom: $bsu;
	}

	a {
		@include link-brand;
		@include font-base-18sb;
		@include link-transition;
	}
}

.c-guides-listing__heading {
	@include font-heading-24b;
	margin-bottom: $ssu;

	@include bp("medium") {
		@include font-heading-36b;
	}
}

.c-guides-listing__body {
	@include font-base-16;

	@include bp("medium") {
		@include font-base-18;
	}
}

.c-guides-listing__wrapper {
	padding: $bsu 0;
	position: relative;
	background: $grey--light;

	.l-page__related-guides-controls & {
		background-color: $grey--lightest;
	}

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 150px;
		background-color: $white;
		content: '';

		.l-page__related-guides-controls & {
			background-color: $grey--lightest;
		}
	}
}

.c-guides-items__wrapper {
	@include layout-wrapper;
	position: relative;
	//z-index: 1;
}


.c-guides-item {
	background-color: $white;
	box-shadow: 0 0 15px rgba($black,0.2);
	margin-bottom: $ssu;
}

.c-guides-item__link {
	display: flex;
	flex-direction: column;

	@include bp("large") {
		flex-direction: row-reverse;
	}
}

.c-guides-item__figuer {
	width: 100%;
	position: relative;

	@include bp("large") {
		width: percentage((1 / 3));
	}
}

.c-guides-item__image {
	@include bp("large") {
		padding-bottom: 0 !important;
		height: 100%;
	}
}

.c-guides-item__category {
	@include font-base-12sbu;
	position: absolute;
	top: 0;
	right: 0;
	padding: 3px 25px;
	z-index: 1;
}

.c-guides-item__content {
	width: 100%;
	padding: ($ssu * 1.5);
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;

	@include bp("large") {
		padding: ($ssu * 1.5) $lsu $bsu;
		width: percentage((2 / 3));
		flex-direction: row;
	}
}

.c-guides-item__meta {
	@include font-base-12-tall;
	width: 100%;
	margin-bottom: $ssu;
}

.c-guides-item__heading {
	@include font-heading-20b;
	width: 100%;
	margin-bottom: $ssu;

	@include bp("large") {
		@include font-heading-28b;
		width: 40%;
	}
}

.c-guides-item__body {
	@include font-base-16;
	flex: 1;


	@include bp("large") {
		padding: 0 $bsu;
	}
}
