// COMPONENT.SOLUTION-ASIDE

.c-solution-aside {
	@include bp-below("large") {
		margin: 0 $bsu;
		margin: 0 var(--layout-gutter-base-right) 0 var(--layout-gutter-base-left);
	}
}

.c-solution-aside__image {
	display: block;
	max-width: 100%;
	margin: 0 auto $lsu;

	object-fit: scale-down;
	&[src$=".svg"] {
		width: 100%;
		object-fit: contain;
	}
}

.c-solution-aside__body {
	@supports(border-left-color: var(--supporting-2-a)) {
		padding-left: $ssu;
		border-left: 2px solid var(--supporting-2-a);
	}
}

.c-solution-aside__section {
	margin-bottom: $bsu;
	&:last-child {
		margin-bottom: 0;
	}
}

.c-solution-aside__section-title {
	display: block;
	margin-bottom: $tsu;

	@include font-heading-20b;
}

.c-solution-aside__section-text {
	@include font-base-18;
}

.c-solution-aside__icon-list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;

	margin-left: -$tsu;
	margin-top: -$tsu;
}

.c-solution-aside__icon-item {
	padding-left: $tsu;
	padding-top: $tsu;
}

.c-solution-aside__icon {
	position: relative;
}

.c-solution-aside__icon-tooltip {
	display: block;
}

@mixin solution-aside-icon-active {
	color: var(--supporting-2-b);
	&::after {
		opacity: 1;
		transform: scale(1);
	}
}

$solution-aside-icon-size: 40px;
$solution-aside-hover-size: 60px;

.c-solution-aside__icon-content {
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: $solution-aside-icon-size;
	width: #{$solution-aside-hover-size / $solution-aside-icon-size}em;
	height: #{$solution-aside-hover-size / $solution-aside-icon-size}em;

	@include link-transition;
	position: relative;
	isolation: isolate;
	&::after {
		content: "";

		background: var(--supporting-2-a);
		opacity: 0;
		transform: scale(0);

		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 100%;

		@include link-transition;
	}

	.c-solution-aside__icon-tooltip & {
		cursor: pointer;
	}

	// Separate rules to support old browsers that
	// think :focus-within is not a valid pseudoclass.
	.c-solution-aside__icon-tooltip:hover &,
	.c-solution-aside__icon-tooltip:focus & {
		@include solution-aside-icon-active;
	}
	.c-solution-aside__icon-tooltip:focus-within & {
		@include solution-aside-icon-active;
	}
}

.c-solution-aside__button {
	@include button("large");
}
