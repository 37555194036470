// COMPONENT.FOOTER

$footer-bp-tablet: "medium";
$footer-bp-desktop: "extra-large";
$footer-newsletter-offset: 500px;

.c-footer {}

.c-footer__newsletter {
	position: relative;
	isolation: isolate;
}

.c-footer__newsletter-background {
	position: absolute;
	bottom: 0;
	width: 50vw;
}
.c-footer__newsletter-background--primary {
	left: 0;
	height: 100%;
}
.c-footer__newsletter-background--secondary {
	right: 0;
	height: 50%;
}

.c-footer__newsletter-layout {
	@include layout-wrapper;
	position: relative;
	z-index: 1;
}

.c-footer__newsletter-body {
	@include bp-below($footer-bp-desktop) {
		width: calc(100% - #{$bsu});
	}
	@include bp($footer-bp-desktop) {
		width: calc(100% - #{$footer-newsletter-offset});
		min-width: 50%;
	}

	display: flex;
	align-items: center;
	padding: $bsu $bsu $bsu 0;

	@include bp-below($footer-bp-tablet) {
		flex-wrap: wrap;
	}
	@include bp($footer-bp-tablet) {
		flex-wrap: nowrap;
	}
}

.c-footer__newsletter-text {
	@include font-heading-20b;

	> :last-child {
		margin-bottom: 0;
	}

	@include bp-below($footer-bp-tablet) {
		width: 100%;

		margin-bottom: $ssu;
		&:last-child {
			margin-bottom: 0;
		}
	}
	@include bp($footer-bp-tablet) {
		margin-right: $bsu;
		&:last-child {
			margin-right: 0;
		}
	}
}

.c-footer__newsletter-button {
	white-space: nowrap;

	@include bp-below($footer-bp-tablet) {
		@include button("medium");
	}
	@include bp($footer-bp-tablet) {
		@include button("large");
	}
}

.c-footer__main {
	// Extend the background to overlap, to avoid any partial pixel gap at weird zoom levels
	padding-top: 5px;
	margin-top: -5px;

	@include bp-below($footer-bp-desktop) {
		padding-top: $bsu + 5px;
	}
}

.c-footer__layout {
	@include layout-wrapper;
	display: grid;

	@include bp-below($footer-bp-tablet) {
		grid-row-gap: $bsu;
		grid-template-areas:
			"social"
			"navigation"
			"contact";
		grid-template-columns: 100%;
	}
	@include bp($footer-bp-tablet) {
		@include bp-below($footer-bp-desktop) {
			grid-gap: $bsu;

			grid-template-areas:
				"logo social"
				"navigation contact";
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
	}
	@include bp($footer-bp-desktop) {
		grid-gap: $lsu;

		grid-template-areas:
			". . social"
			"logo navigation contact";
		grid-template-columns: 1fr 2fr calc(#{$footer-newsletter-offset} - #{$lsu});
	}

	padding-bottom: $lsu;
}

.c-footer__social {
	grid-area: social;

	display: flex;
	align-items: center;

	@include bp($footer-bp-desktop) {
		transform: translateY(-100%);
		justify-content: flex-end;
	}
}

.c-footer__social-list {
	@include list-reset;
	display: flex;

	width: $footer-newsletter-offset;
	margin-left: -$bsu;
}

.c-footer__social-item {
	padding-left: $bsu;
}

.c-footer__social-link {
	display: block;

	font-size: 20px;

	position: relative;
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		right: 50%;
		transform: translate(50%, -50%);

		width: 100%;
		height: 100%;

		min-width: 44px;
		min-height: 44px;
	}

	@include link-transition(color);
	&:hover,
	&:focus {
		color: var(--secondary-contrast);
		outline: 0;
	}

	&:focus-visible {
		&::after {
			@include focus-outline;
		}
	}
}

.c-footer__logo {
	grid-area: logo;

	width: 215px;

	@include bp-below($footer-bp-tablet) {
		display: none;
	}
}

.c-footer__navigation {
	grid-area: navigation;
}

.c-footer__navigation-list {
	@include list-reset;
	columns: 2;
	@include bp-below($footer-bp-tablet) {
		column-gap: $ssu;
	}
	@include bp($footer-bp-tablet) {
		@include bp-below($footer-bp-desktop) {
			column-gap: $bsu;
		}
	}
	@include bp($footer-bp-desktop) {
		column-gap: $lsu;
	}

	margin-top: -$ssu;
}

.c-footer__navigation-item {
	padding-top: $ssu;
}

.c-footer__navigation-link {
	@include font-base-18sb;

	color: inherit;
	text-decoration-color: var(--secondary-a);
	&:hover,
	&:focus {
		text-decoration-line: underline;
	}
}

.c-footer__contact {
	grid-area: contact;
}

.c-footer__contact-heading {
	display: block;
	margin-bottom: $ssu;

	@include font-base-18sb;

	color: inherit;
	text-decoration-color: var(--secondary-a);
	&:hover,
	&:focus {
		text-decoration-line: underline;
	}
}

.c-footer__contact-details {
	display: flex;
	@include bp-below($footer-bp-tablet) {
		flex-wrap: wrap;
	}
	margin-bottom: $ssu;

	margin-top: -$ssu;
	margin-left: -$ssu;

	@include font-base-14;
}

.c-footer__contact-section {
	@include bp-below(500px) {
		width: 100%;
	}
	@include bp(500px) {
		width: 50%;
	}

	padding-top: $ssu;
	padding-left: $ssu;

	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: space-between;

	a {
		color: inherit;
		text-decoration-color: var(--secondary-a);
		&:hover,
		&:focus {
			text-decoration-line: underline;
		}
	}

	p:last-child {
		margin-bottom: 0;
	}
}

.c-footer__contact-link {
	display: block;
	@include font-base-14b;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.c-footer__legal-copyright {
	@include font-base-12;
}

/////////////////
// Back to top //
/////////////////

.c-footer__back-to-top-wrapper {
	@include layout-wrapper;

	display: flex;
	justify-content: flex-end;
}

.c-footer__back-to-top {
	position: fixed;
	bottom: $ssu;

	padding: $ssu 0;
	z-index: $z-back-to-top;
	width: 40px;
	height: 40px;
	font-size: 16px;
	transition: opacity 0.5s linear, visibility 0s linear 0.5s, background 0.3s, box-shadow 0.3s;

	&::before {
		@include font-icon;
		content: $iconf-arrow-up;

		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}

	&.fade-in {
		opacity: 1;
		visibility: visible;
		transition-delay: 0s;
	}

	@include bp-below($footer-bp-desktop) {
		box-shadow: 0 0 2px 2px rgba($white, 0.2);
	}
	@include bp($footer-bp-desktop) {
		&:hover,
		&:focus {
			box-shadow: 0 0 2px 2px rgba($white, 0.2);
		}
	}
	@include bp-below("large") {
		display: inline-block;
		opacity: 0;
		visibility: hidden;
	}
	@include bp("large") {
		display: none;
		opacity: 1;
		visibility: visible;

		vertical-align: bottom;
		border-bottom: 0;
		height: 30px;

		&.sticky-on-desktop {
			display: inline-block;
			opacity: 0;
			visibility: hidden;

			&.fade-in {
				border: none;
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
