$news-bp: "medium";

.c-audience-pathways {
	@include control;

	.l-page__main-controls > &:first-child {
		margin-top: 0 !important;
	}
}

.c-audience-pathways__layout-top {
	background: $grey--light;
	padding: $bsu 0;

	@include bp("large") {
		padding: $lsu 0 $bsu;
	}
}

.c-audience-pathways__layout {
	@include layout-wrapper;
}

.c-audience-pathways__heading {
	@include font-heading-28b;

	@include bp("large") {
		@include font-heading-45b;
	}
}

.c-audience-pathways__eyebrows {
	@include font-base-14-tall;
	display: block;
	margin-bottom: $ssu;

	@include bp("large") {
		@include font-base-18--tall;
		margin-bottom: $ssu;
	}
}

.c-audience-pathways-items__layout-bottom {
	position: relative;

	&::after {
		@include bp("medium") {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100px;
			content: "";
			background: $grey--light;
		}
	}
}

.c-audience-pathways-items-wrapper {
	@include layout-wrapper;
	position: relative;
	z-index: 1;
	padding: 0;

	@include bp("medium") {
		padding: 0 $bsu;
		padding: 0 var(--layout-gutter-base-right) 0 var(--layout-gutter-base-left);
	}
}

.c-audience-pathways-items {
	display: flex;
	justify-content: space-around;
	border-radius: 3px;
	overflow: hidden;
}

.c-audience-pathway-item {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 25%;
	border-top: 1px solid $grey--dark;

	@include bp("medium") {
		border-top: 0;
		border-left: 1px solid $grey--dark;
		min-height: 150px;
	}

	&:first-child {
		border-top: 0;

		@include bp("medium") {
			border-left: 0;
		}
	}
}

@supports (display: grid) {
	.c-audience-pathways-items {
		display: grid;
		grid-template-columns: 100%;

		@include bp("medium") {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		@include bp("large") {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}

		&.c-audience-pathway-items--1 {
			@include bp("medium") {
				grid-template-columns: repeat(1, minmax(0, 1fr));
			}

			@include bp("large") {
				grid-template-columns: repeat(1, minmax(0, 1fr));
			}
		}

		&.c-audience-pathway-items--2 {
			@include bp("large") {
				grid-template-columns: repeat(2, minmax(0, 1fr));
			}
		}

		&.c-audience-pathway-items--3 {
			@include bp("large") {
				grid-template-columns: repeat(3, minmax(0, 1fr));
			}
		}

		&.c-audience-pathway-items--4 {
			@include bp("large") {
				grid-template-columns: repeat(4, minmax(0, 1fr));
			}
		}

		&.c-audience-pathway-items--5 {
			@include bp("large") {
				grid-template-columns: repeat(5, minmax(0, 1fr));
			}
		}

		&.c-audience-pathway-items--6 {
			@include bp("large") {
				grid-template-columns: repeat(6, minmax(0, 1fr));
				grid-gap: 1px 0;
			}
		}
	}

	.c-audience-pathway-item {
		width: 100%;
	}
}

.c-audience-pathway-item__link {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	padding: ($tsu * 1.5) $ssu;

	@include bp("medium") {
		padding: $bsu;
	}
}


.c-audience-pathway-item__item-title {
	@include font-heading-18b;
	text-align: center;

	@include bp("large") {
		@include font-heading-24b;


		.c-audience-pathway-items__6 & {
			@include font-heading-20b;
		}
	}

	.c-audience-pathway-item__link[aria-current="page"] & {
		color: var(--secondary-a);
	}

	.c-audience-pathway-item__link:hover & {
		color: var(--primary-b);
	}
}