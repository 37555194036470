﻿// COMPONENT.EVENT-DETAILS

.c-event-details {
	@include control;
}

.c-event-details__layout {
	@include layout-wrapper;
}

.c-event-details__heading {
	@include control__heading;
}

.c-event-details__container {
	@include flex-extend;
	@include flex-wrap;
	align-items: stretch;

	.c-event-details__map {
		height: 100%;
		min-height: 400px;
	}
}

.c-event-details__details-container {
	width: 100%;
	background: $brand;

	&:first-child:last-child {
		@include bp("medium") {
			width: 100%;
		}

		.c-event-details__details {
			@include flex-extend;
			@include flex-wrap;
			margin-left: -$bsu;
			margin-top: -$bsu;

			@include bp("small") {
				padding-right: 170px; // Leave space for calendar link
			}

			@include bp("medium") {
				padding-right: 190px;
			}
		}

		.c-event-details__detail {
			padding-left: $bsu;
			padding-top: $bsu;
		}

		.c-event-details__directions {
			margin: 0;
		}

		.c-event-details__detail-name {
			@include bp("medium") {
				margin-top: 0;
			}
		}

	}

	.o-layout-content--has-aside & {
		width: 100%;

		@include bp("extra-large") {
			width: 50%;
		}
	}
}

.c-event-details__details {
	position: relative;
	color: $white;
	padding: $bsu;

	@include bp("medium") {
		padding-left: $bsu;
	}

	@include bp("large") {
		padding-top: $lsu;
		padding-right: $lsu;
		padding-bottom: $lsu;
	}

	.o-layout-content--has-aside & {
		@include bp("medium") {
			padding: $bsu;
		}

		@include bp("large") {
			padding: $lsu;
		}
	}
}

.c-event-details__title {
	display: block;
	margin-bottom: $bsu;

	@include font-heading-32b;
}

.c-event-details__detail-name {
	display: block;
	margin-top: $bsu;
	margin-bottom: $tsu;

	.c-event-details__detail:first-child & {
		margin-top: 0;
	}

	@include font-base-14b;
}
.c-event-details__detail-icon {
	display: inline-block;
	padding-right: $tsu;
	vertical-align: middle;
	font-size: 0.6em;
}
.c-event-details__detail-value {
	display: block;
	@include font-base-18b;

	a {
		color: $white;
	}
	a[href^="mailto:"] {
		display: inline-block;
		max-width: 100%;

		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}

.c-event-details__cta {
	margin-top: $bsu;
}

.c-event-details__cta-link {
	@include button;
}

.c-event-details__directions,
.c-event-details__calendar-link {
	display: block;
	margin: $ssu 0;
	color: $white;
	@include font-base-14b;

	&::before {
		@include font-icon;

		display: inline-block;
		margin-right: $tsu;
		vertical-align: middle;
		font-size: 20px;
	}
}

.c-event-details__directions {
	&::before {
		content: $iconf-address;
	}
}

.c-event-details__calendar-link {
	&::before {
		content: $iconf-calendar;
	}

	margin-top: 20px;

	@include bp("small") {
		margin: 0;
		position: absolute;
		top: $bsu;
		right: $bsu;
	}

	@include bp("large") {
		top: $lsu;
		right: $lsu;
	}
}

.c-event-details__map-container {
	width: 100%;

	.o-layout-content--has-aside & {
		width: 100%;
		@include bp("extra-large") {
			width: 50%;
		}
	}
}
