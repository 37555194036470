// CONTROL.RTE

.c-rte {
	@include control;
}

.c-two-column + .c-rte,
.c-rte + .c-two-column {
	// 1.5em is the standard paragraph spacing
	@include bp-below("large") {
		margin-top: calc(1.5em + -$lsu);
		margin-top: calc(1.5em + -1 * var(--control-margin-top));
	}
	@include bp("large") {
		margin-top: calc(1.5em + -$hsu);
		margin-top: calc(1.5em + -1 * var(--control-margin-top));
	}
}

.mce-content-body,
.c-rte {
	@include clearfix;

	& + & {
		margin-top: $bsu;
	}
}

.c-rte__layout {
	@include layout-wrapper--narrow;
}

.c-rte__body-text {
	@include body-text;
}
