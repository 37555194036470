﻿// CONTROL.IMAGE-FEATURE

@mixin content-feature--wide {
	.c-content-feature__layout {
		//@include flex-extend;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: $bsu;

		@include bp("extra-large") {
			grid-gap: $lsu;
		}
	}

	.c-content-feature__layout--right {
		flex-flow: row-reverse;

		.c-content-feature__figure {
			order: 1;
		}
	}

	.c-content-feature__figure {
		flex: 0 0 50%;
		align-self: stretch;
		margin-bottom: 0;
	}

	.c-content-feature__image {
		height: 100%;
	}

	.c-content-feature__body {
		align-self: center;
		padding: $bsu 0;

		@include bp("medium") {
			padding: 0 $bsu 0 0;
		}
	}
}

.l-page:not(.l-page--has-sidebar) {
	@include bp("medium") {
		@include content-feature--wide;
	}
}
.l-page--has-sidebar {
	@include bp("medium") {
		@include bp-below("large") {
			@include content-feature--wide;
		}
	}
	@include bp("extra-large") {
		@include content-feature--wide;
	}
}

.c-content-feature {
	@include control;
}

.c-content-feature__layout-wrapper {
	@include layout-wrapper;
}

.c-content-feature__layout {

	//background-color: $grey--lightest;
}

.c-content-feature__layout--right {}

.c-content-feature__figure {
	@include figure;
}

.c-content-feature__image {}

.c-content-feature__body {
	padding: $bsu 0;
	width: 100%;

	@include bp("large") {
		padding: $bsu;

		.l-page--has-sidebar & {
			padding: $bsu;
		}
	}

	> *:last-child,
	&:last-child {
		margin-bottom: 0;
	}
}

.c-content-feature__heading {
	@include font-heading-24b;
	margin-bottom: $ssu;

	@include bp("large") {
		@include font-heading-36b;
	}
}

.c-content-feature__sub-heading {
	@include font-base-20m;
	margin-bottom: $ssu;

	&.c-content-feature__sub-heading__Red {
		color: $red;
	}

	&.c-content-feature__sub-heading__Green {
		color: $green;
	}

	&.c-content-feature__sub-heading__Blue {
		color: $blue;
	}
}

.c-content-feature__text {
	@include font-base-16;
	margin-bottom: $bsu;

	@include bp("large") {
		@include font-base-18;
		margin-bottom: $bsu * 1.5;
	}
}

.c-content-feature__link {
	display: flex;
	align-items: center;

	@include font-base-20sb;

	&::after {
		@include font-icon;
		content: $iconf-arrow-right;
		padding-left: 0.5em;
		color: var(--secondary-a);
	}
}

.c-content-feature__video {
	position: relative;
	display:block;
}

.c-content-feature__video-icon {
	@include abs-center;
	width: 66px;
	height: 54px;
	@include link-transition;
	background-color: rgba($youtube--red, 0.8);
	border-radius: 15px;

	&:hover {
		background-color: rgba($youtube--red, 1);
	}

	&::before {
		@include pseudo-element;
		left: 25px;
		top: 17px;
		border-width: 10px 20px;
		border-color: transparent;
		border-left-color: $white;
		border-style: solid;
	}

	@include bp("small") {
		width: 76px;
		height: 62px;

		&::before {
			left: 25px;
			top: 18px;
			border-width: 13px 26px;
		}
	}

	@include bp("medium") {
		width: 90px;
		height: 76px;

		&::before {
			left: 30px;
			top: 23px;
			border-width: 15px 30px;
		}
	}
}