﻿// COMPONENT.PROJECT-DETAILS

.c-project-sponsor {
	margin-left: auto;
	margin-right: auto;
	.l-page__sidebar > {
		margin-top: 0;
	}
}

.c-project-sponsor__img-wrapper {
	max-width: 320px;
	margin: 0 auto;

	@include bp("large"){
		max-width: auto;
	}
}

.c-project-sponsor__text {
	text-align: center;
	margin: $ssu 0;

	> p {
		@include font-base-16;
	}
}