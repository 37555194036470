// COMPONENT.NAVIGATION

// Main Navigation
$navigation-bg: $white;

$navigation-bp: $header-bp;
.c-navigation {
	display: none;
	width: 100%;
	background-color: $navigation-bg;

	@include bp($navigation-bp) {
		display: block;
		align-self: center;
		width: auto;
		background-color: transparent;
	}

	&[aria-expanded="true"] {
		display: block;
		position: absolute;
		top: $header-height;
		top: var(--header-height);
		left: 0;
		height: calc(100vh - #{$header-height});
		height: calc(100vh - var(--header-height));
		overflow: auto;
	}
}

.c-navigation__items {
	@include list-reset;
	padding-top: $tsu;

	@include bp($navigation-bp) {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: stretch;
		padding: 0;
		margin-right: $lsu;
	}
}

.c-navigation__item {
	@include ui-border('bottom');

	@include bp($navigation-bp) {
		position: relative;
		border: 0;
		text-align: center;
		vertical-align: bottom;
		display: flex;
		align-items: stretch;
	}

	@include bp("extra-large") {
		margin: 0 $ssu;
	}

	&[aria-expanded="true"] {
		> .c-megamenu__heading {
			color: var(--link--hover);
		}
	}
}

.c-navigation__link {
	@include font-base-20b;
	display: block;
	padding: $tsu $bsu $tsu $ssu;
	color: $body-color;

	position: relative;

	@include bp($navigation-bp) {
		display: inline-block;
		padding: $ssu ($ssu + $tsu) $ssu $ssu;
		white-space: nowrap;

		&:hover {
			color: var(--link--hover);
		}
	}
}

.c-navigation__link--home {
	font-size: rem(20px);

	@include bp($navigation-bp) {
		line-height: 1;
	}
}

.c-navigation__expander {
	position: absolute;
	right: $bsu * 1.5;
	top: 50%;
	transform: translateY(-50%);

	@include bp($navigation-bp) {
		right: 0;
	}

	&::before {
		display: block;

		@include font-icon;

		@include bp-below($navigation-bp) {
			content: $iconf-arrow-right;

			.c-navigation__item[aria-expanded="true"] & {
				transform: rotate(90deg);
			}
		}
		@include bp($navigation-bp) {
			content: $iconf-arrow-down;

			font-size: 0.7em;
			.c-navigation__item[aria-expanded="true"] & {
				transform: rotate(-180deg);
			}
		}

		@include link-transition(transform);
		transform-origin: 50% 50%;
	}
}

// Navigation Drop Down
.c-navigation-dropdown__items {
	@include list-reset;

	display: none;
	padding: $ssu 0 $ssu $bsu;
	width: 100%;
	background: $navigation-bg;

	@include bp($navigation-bp) {
		position: absolute;
		padding: 0;
		min-width: 250px;
		left: 50%;
		top: 100%;
		transform: translateX(-50%);
		box-shadow: 0 0 10px 0 rgba($black, 0.2);
	}

	[aria-expanded="true"] > &,
	.no-js :hover > & {
		display: block;
	}
}

.c-navigation-dropdown__item {
	display: inline-block;
	width: 100%;
	margin-bottom: $ssu;

	@include bp($navigation-bp) {
		@include ui-border("bottom");
		margin: 0;
	}
}

.c-navigation-dropdown__link {
	@include font-base-14b;
	display: block;
	padding: $tsu 0;
	color: $body-color;

	&::before {
		display: none;
	}

	@include bp($navigation-bp) {
		position: relative;
		padding: $ssu $lsu $ssu $bsu;

		&:hover {
			text-decoration: underline;
		}
	}
}

// Language container - append around used to insert language picker in here when it's visible
.c-navigation__language {
	padding: $bsu;

	@include bp('medium') {
		display: none;
	}
}