﻿
// CONTROL.FORM

@use "sass:math";

.c-form {
	@include control;
	max-width: 800px;
	margin: 0 auto;
}

.c-form__layout {
	@include layout-wrapper--narrow;
}

.ng-scope {
	textarea {
		resize: vertical;
	}

	.c-fake-radio__display {
		flex: 0 0 25px;
	}
}

.umbraco-forms-form,
.c-form__form {
	max-width: 800px;
}

.c-form,
.c-document-download__form {
	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	input[type="file"],
	input[type="url"],
	select,
	textarea {
		@include form-input;
	}

	select ~ .field-validation-error,
	select ~ .c-form__error {
		border-color: $error;
		margin-bottom: $tsu;
	}

	.c-form__error {
		color: $error;
		margin-bottom: $tsu;
	}

	textarea {
		height: 150px;
	}
}

.c-form__heading {
	@include control__heading;
	margin-bottom: 20px;
}

.c-form__field {
	margin-bottom: $ssu;
	/*    &:last-child {
        margin-bottom: 0;
    }*/
}

.c-form__field-label {
	@include font-base-16;
}

.c-form__required-indicator {
	color: $red;
	margin-left: 5px;
}

.c-form__controls {
	margin-top: $ssu;
}

.c-form__control {
	@include button;
}

.c-form__field-checkbox {
	display: flex;
}

.field-validation-error,
.c-form__error-message,
.validation-summary-errors,
.c-form__error {
	display: block;
	margin-top: 5px;

	@include font-base-14;
	color: $error;
}

.c-form__error-message {
	display: none;
}

.has-error .c-form__error-message {
	display: block;
}

.titleanddescription {
	// This is how Umbraco Forms organises its markup
	[data-umb] {
		@include body-text;
	}
}

.multiplechoice {
	&[style] {
		// Optional fields, once shown, still have a style attribute
		margin-top: -$tsu;
		padding-bottom: $ssu;
		margin-left: $ssu;
	}
}

.c-form__description {
	display: block;
	margin-top: $tsu;
	margin-bottom: $bsu;

	@include font-base-18;
	line-height: 1.55556;

	a {
		color: var(--link);
		text-decoration-line: underline;
		text-decoration-color: var(--link--hover);
		font-family: "Work Sans", Arial, Helvetica, sans-serif;
		font-size: 16px;
		font-weight: 700;
		line-height: 1.625;


		&:hover, &:focus {
			color: var(--link--hover);
		}

		@include bp("medium") {
			font-size: 18px;
			line-height: 1.55556;
		}
	}

	&--topmargin{
		margin-top: 40px;
	}
}

.c-form__helptext {
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.2;
	color: $grey--dark;
}

// Fake inputs
.c-fake-checkbox {
	@include fake-input;

	&:checked ~ .c-fake-checkbox__display {
		&::before {
			border-color: $blue;
		}
	}

	&:focus ~ .c-fake-checkbox__display {
		@include focus-outline;
	}
}

.c-fake-checkbox__display {
	@include fake-input__display;

	@include link-transition;

	&::before {
		@include link-transition;
		content: '';
		position: absolute;
		left: 4px;
		top: 5px;
		display: block;
		width: 16px;
		height: 8px;
		border: 2px solid transparent;
		border-width: 0 0 2px 2px;
		background: transparent;
		transform: rotate(-45deg);
	}
}

// Fake Radio
.c-fake-radio {
	@include fake-input;

	&:checked ~ .c-fake-radio__display {
		&::before {
			background-color: $blue;
		}
	}

	&:focus ~ .c-fake-radio__display {
		@include focus-outline;
	}
}

.c-fake-radio__display {
	@include fake-input__display;
	border-radius: 25px;

	@include link-transition;

	&::before {
		@include link-transition;
		content: '';
		position: absolute;
		left: 3px;
		top: 3px;
		display: block;
		width: 17px;
		height: 17px;
		border-radius: 17px;
		background-color: transparent;
	}
}

// Fake file
.c-fake-file {
	@include fake-input;
}

.c-fake-file__display {
	@include flex-extend;
	width: 100%;

	.c-fake-file:focus + & {
		@include focus-outline;
	}

	&.has-error {
		.c-fake-file__display-value {
			border-color: $error;
		}

		.c-fake-file__display-button {
			background: $error;
		}
	}

	cursor: pointer;

	.c-fake-file:disabled + & {
		cursor: not-allowed;

		.c-fake-file__display-value {
			background: $grey--light;
		}

		.c-fake-file__display-button {
			cursor: not-allowed;
			color: $white;

			&,
			&:hover,
			&:focus {
				background-color: $grey;
			}
		}
	}
}

.c-fake-file__display-value {
	@include form-input;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&:not(.has-value) {
		color: $grey;
	}
}

.c-fake-file__display-button {
	margin-left: $tsu;

	@include flex-extend;
	align-items: center;
	justify-content: center;
	width: 160px;
}

.c-form__section {
	border-bottom: 1px solid #ececf2;
	padding-bottom: 40px;
	margin-bottom: 40px;
}

.c-form__button-wrapper {
	text-align: right;
	padding-bottom: 40px;

	.c-form__button {
		@include button;
		background-color: #00143c;
		color: #ffffff;

		&:hover {
			background-color: #17cf59;
		}

		@include bp("large") {
			@include button-size("large");
		}
	}
}

.c-form__sublist {
	margin: 20px;

	.c-form__label {
		font-weight: 300 !important;
	}
}
//====-=---------- UMBRACO.FORM

.umbraco-forms-form {
	max-width: 800px;
}

.umbraco-forms-caption {
	@include font-base-18;
	margin-bottom: 1.5em;
}

.umbraco-forms-fieldset {
	@include ui-border('bottom');

	.c-document-download & {
		border-bottom-color: $grey;
	}

	padding-bottom: $bsu;
	margin-bottom: $bsu;

	legend {
		@include control__heading;
		margin-bottom: $ssu;
	}
}

.umbraco-forms-field {
	margin-bottom: $ssu;

	&:last-child {
		margin-bottom: 0;
	}

	&.checkbox {
		position: relative;
		padding-left: $ssu;

		input[type="checkbox"] {
			position: absolute;
			left: 0;
			top: 4px;
		}
	}
}

.umbraco-forms-label-wrapper {
	order: 2;
}

.umbraco-forms-label, .c-form__label {
	@include font-base-16sb;
	line-height: 1.2;
	display: block;
	margin-bottom: 10px;
	margin-top: 3px;
}

.umbraco-forms-tooltip {
	display: block;
	margin-top: $tsu;
	margin-bottom: $tsu;

	@include font-base-14;
	line-height: 1.2;
	color: $grey--dark;
}

.umbraco-forms-form .btn {
	@include button;

	@include bp("large") {
		@include button-size("large");
	}
}

.umbraco-forms-indicator {
	color: $error;
}

.umbraco-forms-field-wrapper {
	margin-top: $tsu;

	.date & {
		position: relative;

		@include bp("small") {
			max-width: 250px;
		}

		&::after {
			position: absolute;
			display: block;
			right: $ssu;
			top: 15px;
			font-family: #{$icomoon-font-family};
			content: $iconf-calendar;
			pointer-events: none;
		}
	}

	.checkbox & {
		margin-top: 0;
	}

	.singlecheckbox & {
		order: -1;
		margin-right: $tsu;
	}

	.titleanddescription & {
		margin-top: 0;
	}

	.checkboxlist, .radiobuttonlist {
		label {
			display: inline-block;
			vertical-align: top;
			margin-top: -4px; // Align with checkbox / radio button
			margin-bottom: $ssu;
			margin-left: 3px;
			max-width: calc(100% - 23px);

			@include font-base-16;
			line-height: 1.5;
		}

		label:last-of-type {
			margin-bottom: 0;
		}
	}
}

.umbraco-forms-navigation {
	text-align: right;
}
