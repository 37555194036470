// COMPONENT.BOOKING-PANEL

.c-booking-panel {
	@include control;
}

.c-booking-panel__layout {
	@include layout-wrapper;
}

.c-booking-panel__body {
	@include flex-extend;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	@include bp("medium") {
		flex-direction: row;
	}

	box-shadow: inset 0 0 0 1px $base-ui-color;
	padding: $bsu;
	background: $grey--lightest;
}

.c-booking-panel__details {}

.c-booking-panel__details-primary {
	@include flex-extend;
	flex-wrap: wrap;
	@include bp("large") {
		flex-wrap: nowrap;
	}
}

.c-booking-panel__details-secondary {
	@include font-base-16;
	margin-top: $bsu;

	:last-child {
		margin-bottom: 0;
	}
}

.c-booking-panel__details-list {
	@include flex-extend;
	flex-grow: 1;

	width: 100%;
	margin-left: -$bsu;
	@include bp("medium") {
		margin-left: -$lsu;
	}
	@include bp("large") {
		width: auto;
	}

	+ .c-booking-panel__details-list {
		margin-top: $bsu;
		@include bp("large") {
			margin-top: 0;
			margin-left: 0;
		}
	}
}

.c-booking-panel__details-item {
	padding-left: $bsu;
	@include bp("medium") {
		padding-left: $lsu;
	}
}

.c-booking-panel__details-item-title {
	@include font-base-16;
}

.c-booking-panel__details-item-value {
	@include font-base-18b;
}

.c-booking-panel__details-item-note {
	display: block;
	margin-top: $ssu;
	@include font-base-14;
}

.c-booking-panel__action {
	margin-top: $lsu;
	@include bp("medium") {
		margin-top: 0;
		margin-left: $lsu;
	}
}

.c-booking-panel__action {
	@include button("large");
	white-space: nowrap;
}
