$news-bp: "medium";

.c-news-listing {
	@include control;
}
// Second block to ensure order is maintained when compiling
.c-news-listing {
	.l-page__main-controls &:first-child {
		margin-top: 0;
	}

	@include bp($news-bp) {
		background-image: url("/content/images/interface/bg/white-circles-pattern-quarter-right.svg");
		background-position: bottom right;
		background-repeat: no-repeat;
	}
}

.c-news-listing__layout {
	@include layout-wrapper;
}

.c-news-listing__header {
	display: block;
	padding: $bsu 0 $bsu;

	@include bp("large") {
		padding: $hsu 0 $bsu;
	}

	background: $grey--light;
}

.c-news-listing__header-layout {
	@include layout-wrapper;
}

.c-news-listing__heading {
	@include font-heading-28b;

	@include bp("large") {
		@include font-heading-52b;
	}
}

.c-news-listing__introduction-layout {
	display: flex;
	justify-content: space-between;
	margin-left: -$bsu;
	margin-top: $bsu;
	flex-direction: column;

	@include bp("medium") {
		flex-direction: row;
	}
}

.c-news-listing__introduction {
	@include font-base-16;
	padding-left: $bsu;
	width: 100%;

	@include bp("medium") {
		@include font-base-18;
		width: 50%;
		padding-top: $bsu;
	}
}

.c-news-listing__no-results {
	// Ensure it appears above the background
	position: relative;
}

.c-news-listing__filter-controls {
	padding-left: $bsu;
	padding-top: $bsu;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;

	@include bp("medium") {
		width: 50%;
	}
}

.c-news-listing__filter-toggle {}

.c-news-listing__filter-toggle-icon {}

.c-news-listing__filter {}

.c-news-listing__filter-section {}

.c-news-listing__filter-section-title {}

.c-news-listing__filter-item-list {}

.c-news-listing__filter-item {}

.c-news-listing__filter-item-button {}


//////////
// LIST //
//////////
.c-news-listing__list-background {
	position: relative;
	&::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: -1px; // Ensure no rounding/anti-aliasing issues can occur
		height: 101px;
		background: $grey--light;
	}
}

.c-news-listing__list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;

	margin-left: -$ssu;
	margin-top: -$ssu;
}

.c-news-listing__list-item {
	padding-left: $ssu;
	padding-top: $ssu;

	width: 100%;
	@include bp("medium") {
		width: 50%;
	}
	@include bp("large") {
		width: 25%;
	}

	// Large items
	&:nth-child(1),
	.c-news-listing__list:not(.c-news-listing__list--has-promo) &:nth-child(10n+8),
	.c-news-listing__list:not(.c-news-listing__list--has-promo) &:nth-child(10n+11),
	.c-news-listing__list--has-promo &:nth-child(10n+9),
	.c-news-listing__list--has-promo &:nth-child(10n+12) {
		width: 100%;
		@include bp("large") {
			width: 50%;
		}

		@include bp-below("medium") {
			@include news-list-item("small");
		}
		@include bp("medium") {
			@include news-list-item("large");
		}
	}

	// Promo
	.c-news-listing__list--has-promo &:nth-child(6) {
		width: 100%;
		.c-cta {
			margin: 0;
			padding: 0;
		}
	}

	// Everything else (small items)
	.c-news-listing__list--has-promo &:nth-child(n+2):not(:nth-child(6)):not(:nth-child(10n+9)):not(:nth-child(10n+12)),
	.c-news-listing__list:not(.c-news-listing__list--has-promo) &:nth-child(n+2):not(:nth-child(10n+8)):not(:nth-child(10n+11)) {
		@include news-list-item("small");
	}
}

@supports (display: grid) {
	.c-news-listing__list {
		display: grid;

		margin: 0;
		grid-gap: $ssu;

		grid-template-columns: 100%;
		@include bp("medium") {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
		@include bp("large") {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}
	}

	.c-news-listing__list-item {
		padding: 0;
		width: 100%;

		// Large items
		&:nth-child(1),
		.c-news-listing__list:not(.c-news-listing__list--has-promo) &:nth-child(10n+8),
		.c-news-listing__list:not(.c-news-listing__list--has-promo) &:nth-child(10n+11),
		.c-news-listing__list--has-promo &:nth-child(10n+9),
		.c-news-listing__list--has-promo &:nth-child(10n+12) {
			width: 100%;

			@include bp("medium") {
				grid-column-end: span 2;
			}
			@include bp("large") {
				grid-row-end: span 2;
			}
		}

		// Promo
		.c-news-listing__list--has-promo &:nth-child(6) {
			width: 100%;

			@include bp("medium") {
				grid-column-end: span 2;
			}
			@include bp("large") {
				grid-column-end: span 4;
			}
		}
	}
}