﻿// THEME

$white: #fff;
$black: #000;

$grey--lightest: #f9f9f9;
$grey--light: #ececf2;
$grey: #d0d0d0;
$grey--dark: #666;
$grey--darkest: #333;
$charcoal: #202020;

$blue: #007DEB;
$blue--water: #253C8F;
$navy: #041C2C;
$ocean: #00143C;

$green: #17CF59;
$earth: #00604E;

$red: #f04040;
$red--dark: #9C1C2C;

$yellow: #FFCA0D;
$yellow--sunlight: #EB9317;

$brand: $ocean;
$brand--alt: darken($brand, 10);

$youtube--red:#ff0000;

// Shared non-theme colours, e.g. alert, errors
$success: #008000;
$success--light: lighten($success, 40);
$warning: #ffa500;
$warning--light: lighten($warning, 40);
$error: #f00;
$error--light: #fff8f8;

// Assign colours to common variables
$body-color: $navy;
$base-ui-color: $grey--light;

// Main Colours
$primary-a: $brand !default;
$primary-b: $white !default;
$secondary-a: $green !default;
$secondary-b: $white !default;

$link: $brand !default;
$link-hover: $green !default;

$supporting-1-a: $blue !default;
$supporting-1-b: $white !default;

$supporting-2-a: $red !default;
$supporting-2-b: $white !default;
