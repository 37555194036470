// OBJECT.LIST

@mixin list-reset {
    padding: 0;
    margin: 0;
    list-style: none;
}

@mixin list-inline {
    > li {
        display: inline;
    }

    > [data-divider] {
        &::after {
            content: attr(data-divider);
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }
}

@mixin list-stacked {
    > li {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

// OBJECT.LIST

.o-list--reset {
    @include list-reset;
}

.o-list--inline {
    > li {
        display: inline;
    }

    > [data-divider] {
        &::after {
            content: attr(data-divider);
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }
}

.o-list--stacked {
    > li {
        &:last-child {
            margin-bottom: 0;
        }
    }
}
