﻿// COMPONENT.CONTACT-PAGE

$contact-page-bp: "large";

.c-contact-page__hero {
	margin-bottom: $lsu;
}

.c-contact-page__layout-wrapper {
	@include layout-wrapper;
}

// ### INTRO LAYOUT
.c-contact-page__layout {
	@include control;
	@include bp($contact-page-bp) {
		@include flex-extend;
		margin-left: -$bsu;
		//margin-top: -$hsu;
	}
}

.c-contact-page__layout-details,
.c-contact-page__layout-form {
    margin-bottom: $bsu;
	@include bp($contact-page-bp) {
		padding-left: $bsu;
		//padding-top: $hsu;
	}
}

.c-contact-page__layout-details {
	@include bp($contact-page-bp) {
		flex-grow: 1;
	}
}

.c-contact-page__layout-form {
    @include bp($contact-page-bp) {
        flex-shrink: 0;
        width: 50%;

        .umbraco-forms-form {
            background: $grey--lightest;
            max-width: none;
            padding: $lsu;
        }
    }

    .c-form {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.c-contact-page__heading {
	@include layout-wrapper;

	@include font-heading-36b;
	display: block;
	margin-bottom: $lsu;

	@include bp($contact-page-bp) {
		margin-bottom: $hsu;
	}
}

.c-contact-page__lede {
    @include font-base-18;
    margin-bottom: $bsu;

    @include bp($contact-page-bp) {
        @include font-base-20;
    }

    > p {
        @include font-base-18;

        @include bp($contact-page-bp) {
            @include font-base-20;
        }
    }
}

.c-contact-page__form-heading {
	@include font-heading-24b;
	display: block;
	margin-bottom: $bsu;
}

.c-contact-page__map {
	width: 100%;
	min-height: 400px;
}

// ### PERSON LIST
.c-contact-page__person-list {
	display: block;
}

.c-contact-page__person-heading {
	@include font-heading-24b;
	margin-bottom: $bsu;
}

.c-contact-page__person-name {
	@include font-base-16b;
	display: block;
}

.c-contact-page__person-position {
	@include font-base-16;
	display: block;
	margin-bottom: $bsu;
}

// ### DETAIL LIST
.c-contact-page__detail-list {
	@include list-reset;
}

.c-contact-page__detail-list-item {
	@include flex-extend;
	margin-bottom: $bsu;
}

.c-contact-page__detail-list-icon {
	@include circle(40px);
    flex-shrink: 0;
	border: 1px solid var(--primary-a);
    display: flex;
    justify-content:center;
    align-items: center;
    margin-right: $ssu;
}

.c-contact-page__detail-list-content {
	flex-grow: 1;

	> :last-child {
		margin-bottom: 0;
	}
}

// ### LOCATION ITEMS
.c-contact-page__locations-heading {
	@include font-heading-24b;
	margin-bottom: lsu;
}

.c-contact-page__location-list {
	@include list-reset;
	@include control;
}

.c-contact-page__location {
	padding-top: $lsu;

	@include bp($contact-page-bp) {
		@include flex-extend;
	}

	&:nth-child(2n) {
		flex-direction: row-reverse;
	}
}

.c-contact-page__location-body {
	background: $grey--lightest;
	padding: $bsu;
	@include bp($contact-page-bp) {
		padding: $lsu;
	}

	@include bp($contact-page-bp) {
		flex-grow: 1;
	}
}

.c-contact-page__location-heading {
	@include font-heading-20b;
	margin-bottom: $bsu;
}

.c-contact-page__location-map-container {
	@include bp($contact-page-bp) {
		flex-shrink: 0;
		width: 50%;
	}
}