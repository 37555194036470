// COMPONENT.PRODUCT

.c-product__section {}

.c-product__section-layout {
	@include layout-wrapper;
}

.c-product__section-title {}

.c-product__section-description {
	@include body-text;
}
