/* COMPONENT.HEADER-NOTIFICATION */

.c-header-notification {
	background: $grey--lightest;
	color: $brand;
	padding: $ssu 0;
	text-align: left;
}

.c-header-notification__layout {
	// o-layout-wrapper o-layout-wrapper--narrow
	@include layout-wrapper;
}

.c-header-notification__heading {
	margin-bottom: $ssu;
}

.c-header-notification__text {
	:last-child {
		margin-bottom: 0;
	}

	a {
		@include link-plain;
		color: var(--link--hover);
		text-decoration: underline;
	}
}
