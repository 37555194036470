// COMPONENT.HAMNAV
.c-hamnav__wrapper {
	position: fixed;
	z-index: 2;
	top: 0;
	right: 0;
	padding-top: var(--header-height);
	overflow: hidden;
	background-color: rgba($primary-a,0.92);

	@include bp-below("medium") {
		margin: 0;
		padding: var(--header-height) 0;
		width: 100%;
		height: 100%;
	}

	@include bp("medium") {
		margin: -5px 0 -5px -5px;
		padding: var(--header-height) 0 5px 5px;
		width: 400px;
		height: 100%;
	}

	@media(min-width: 1440px) {
		width: 33%;
	}

	@media(min-width: 2000px) {
		width: 38%;
	}

	visibility: hidden;

	&[aria-expanded="true"] {
		//animation: 0.5s becomeVisible forwards;
		animation: 0.5s slideinRight forwards, 0.5s becomeVisible forwards;

		.c-hamnav {
			animation: 0.5s becomeVisible forwards;
		}
	}

	&[aria-expanded="false"] {
		//animation: 0.5s becomeHidden forwards;
		animation: 0.5s slideoutRight forwards, 0.5s becomeHidden forwards;

		.c-hamnav {
			animation: 0.5s becomeVisible forwards;
		}
	}
}

.c-hamnav {
	width: 100%;
	height: 100%;
	max-height: calc(100vh - #{$header-height});
	visibility: hidden;
	box-shadow: 0 0 5px 0 rgba($black, 0.2);
	overflow: auto;
}

.c-hamnav__sections {
	@include list-reset;
	padding: $ssu $bsu;
}

.c-hamnav__section {
	margin: $ssu 0;
}

.c-hamnav__home {
	display: block;

	@include bp("large") {
		display: none;
	}
}

.c-hamnav__section--has-children {
	.c-hamnav__section-link {
		&::before {
			@include font-icon;
			content: $iconf-plus;
			color: var(--link--hover);
			position: absolute;
			left: 0;
			top: 3px;
		}
	}
}

.c-hamnav__section-link {
	@include font-base-20sb;

	display: inline-block;
	padding-left: $bsu;
	position: relative;

	@supports (border-bottom: 4px solid var(--primary-a)) {
		&[aria-current]:not([aria-current="false"]) {
			padding-bottom: $tsu;

			&::after {
				content: "";
				position: absolute;
				bottom: 0;
				left: $ssu;
				right: 0;
				border-bottom: 4px solid var(--primary-a);
			}
		}
	}
}

.c-hamnav__children {
	@include list-reset;

	display: none;
}
.c-hamnav__section[aria-expanded="true"] {
	.c-hamnav__section-link {
		&::before {
			content: $iconf-minus;
		}
	}

	.c-hamnav__children {
		display: block;
	}
}

.c-hamnav__child {
	padding-left: $bsu;
	margin: $tsu 0;
}

.c-hamnav__child-link {
	@include font-base-16;
	&:hover,
	&:focus {
		color: var(--link--hover);
	}
}


.c-hamnav__footer {
	padding: $bsu;
}

.c-hamnav__social-list {
	display: flex;
}

.c-hamnav__social-list {
	@include list-reset;
	display: flex;
	margin-left: -$bsu;
}

.c-hamnav__social-item {
	padding-left: $bsu;
}

.c-hamnav__social-link {
	display: block;
	font-size: 20px;
	position: relative;

	&::after {
		content: "";
		position: absolute;
		top: 50%;
		right: 50%;
		transform: translate(50%, -50%);
		width: 100%;
		height: 100%;
		min-width: 44px;
		min-height: 44px;
	}

	@include link-transition(color);

	&:hover,
	&:focus {
		color: var(--primary-b);
		outline: 0;
	}

	&:focus-visible {
		&::after {
			@include focus-outline;
		}
	}
}