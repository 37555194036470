// COMPONENT.FEATURED-NEWS

.c-featured-news {
	// @include control;
	position: relative;
	margin-bottom: -#{$lsu};
	display: none;

	@include bp("large") {
		display: block;
		max-height: 860px;
		overflow: hidden;
	}
}

.c-featured-news__media {
	width: calc( 50% - #{$lsu});
	//position: relative;
	z-index: 1;

	@include bp-below(1440px) {
		width: 50%;
	}
}

.c-featured-news__image {}

.c-featured-news__body {
	width: calc( 50% + #{$lsu});
	height: calc( 100% - #{$lsu});
	position: absolute;
	z-index: 0;
	background-color: $grey--lightest;
	margin-left: calc( 50% - #{$lsu});
	justify-content: center;
	align-items: center;
	display: flex;
	top: 0;
	padding-left: $lsu;
	padding-right: $lsu;
	padding-top: $lsu;
	padding-bottom: $lsu;
	background-image: url("/content/images/interface/bg/white-circles-pattern-quarter-right.svg");
	background-position: bottom right;
	background-repeat: no-repeat;

	@include bp-below(1440px) {
		width: 50%;
		margin-left: 50%;
		padding-left: $bsu;
		padding-right: $bsu;
	}

	@include bp-below("extra-large") {
		padding-left: $bsu;
		padding-right: $bsu;
		background-size: 150px;
	}
}

.c-featured-news__body-content {
	@include bp-below("large") {
		padding-right: $lsu;
	}

	@include layout-split(right, 50%);
}

.c-featured-news__heading {
	@include font-heading-30b;
	margin-top: $ssu;
	&:first-child {
		margin-top: 0;
	}
	margin-bottom: $bsu;

	@include bp("large") {
		@include font-heading-40b;
	}

	@include bp("extra-large") {
		@include font-heading-52b;
	}
}

.c-featured-news__meta {
	@include font-base-16;
	display: flex;
	align-items: center;
	margin-bottom: ($tsu / 2);

	@include bp("extra-large") {
		@include font-base-18;
	}
}

.c-featured-news__meta-divier {
	width: 1px;
	display: inline-block;
	height: 18px;
	margin: 0 $tsu;
}

.c-featured-news__date {}

.c-featured-news__category {}

.c-featured-news__author {
	display: block;
	margin: 0 0 $ssu;

	@include font-base-16;
	@include bp("extra-large") {
		@include font-base-18;
	}
}

.c-featured-news__abstract {
	@include font-base-20;
	line-height: lh(32, 20);
	margin-bottom: $bsu;
	padding-right: ($ssu * 5);

	@include bp-below("extra-large") {
		display: none;
	}
}

.c-featured-news__link {
	@include button("medium");
	border: 1px solid var(--primary-a);
}
