@media print {

	.c-header {
		&,
		&.is-sticky {
			position: absolute;
			&.is-up {
				transform: none;
			}
		}
		box-shadow: none;
	}

	.c-header__mobile-button {
		display: none;
	}

	.c-cta__body {
		page-break-inside: avoid;
	}

	.c-embed {
		page-break-inside: avoid;
	}

	.m-quote {
		page-break-inside: avoid;
	}

	.c-listing-stacked__item {
		page-break-inside: avoid;
	}

	.c-related-item {
		page-break-inside: avoid;
	}

	.c-subnav__item {
		display: block;
		page-break-inside: avoid;

		&.is-selected::before {
			top: 0;
			height: 100%;
		}
	}

	.c-share__items {
		page-break-inside: avoid;
	}

	.l-page__footer .c-share {
		display: none;
	}

	.c-footer-back-to-top {
		display: none !important;
	}

	.c-newsletter {
		display: none;
	}
}