/* COMPONENT.MEGAMENU */

$megamemu__intro-width: 400px;

$megamenu__column-gap: 50px;
$megamenu__column-number: 4;
$megamenu__column-number--with-promos: 2;
$megamenu__column-number--with-promos-vars: calc(4 - var(--megamenu-number-promos, 2));

$megamenu-bp: $header-bp;

.c-megamenu {
	--megamenu-column-gap: 50px;


	display: none;
	background-color: rgba($brand, 0.92);

	&[aria-expanded="true"],
	[aria-expanded="true"] > & {
		display: block;
	}

	&[aria-expanded="true"] {
		box-shadow: 0 8px 6px -6px rgba($black, 0.2);
	}

	@include bp($megamenu-bp) {
		transition: max-height 0.5s ease-in-out;
		position: absolute;
		.c-navigation__item--megamenu[aria-expanded="true"] & {
			z-index: 1;
		}

		top: $header-height;
		top: var(--header-height);
		left: 0;
		display: block;
		width: 100%;

		max-height: 0;
		visibility: hidden;
		overflow: hidden;

		text-align: left;
		//background: $white;
		box-shadow: 0 8px 6px -6px rgba($black, 0.2);

		&[aria-expanded="true"],
		[aria-expanded="true"] > & {
			max-height: 500px;
			max-height: calc(100vw - var(--header-height));
			visibility: visible;
		}

		&.allow-animations,
		.allow-animations > & {
			animation: 0.5s becomeHidden forwards;
		}

		&.allow-animations[aria-expanded="true"],
		.allow-animations[aria-expanded="true"] > & {
			animation: 0.5s becomeVisible forwards;
		}

		.no-js .c-navigation__item:hover & {
			display: block;
		}
	}
}

.c-megamenu__wrapper {
	@include clearfix;

	@include bp($megamenu-bp) {
		max-width: em($layout-wrapper-max-width);
		padding: 0 $bsu;
		padding-top: $ssu * 3;
		padding-bottom: $ssu * 3;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
	}
}

.c-megamenu__body {
	@include bp($megamenu-bp) {
		@include flex-extend;
	}
}

.c-megamenu__body--single-promo {
	--megamenu-number-promos: 1;
}

.c-megamenu__intro {
	width: 100%;
	margin-bottom: $bsu;

	display: none;
	@include bp($megamenu-bp) {
		display: block;
	}
}

.c-megamenu__intro-heading {
	display: inline-block;
	margin-bottom: 0;
	padding: 0;

	@include font-heading-36b;
}

.c-megamenu__items {
	@include list-reset;
	flex-grow: 1;
	padding: $ssu 0 $ssu $bsu;

	@include bp($megamenu-bp) {
		width: 100%;
		float: right;
		columns: $megamenu__column-number--with-promos;
		columns: $megamenu__column-number--with-promos-vars;
		padding: 0 $megamenu__column-gap 0 0;
		padding: 0 var(--megamenu-column-gap) 0 0;
		&:last-child {
			// No promos
			columns: $megamenu__column-number;
			padding-right: 0;
		}
		column-gap: $megamenu__column-gap;
		column-gap: var(--megamenu-column-gap);
	}
}

.c-megamenu__item {
	display: inline-block;
	width: 100%;
	margin-bottom: $ssu;

	@include bp($megamenu-bp) {
		display: block;
		margin: 0;

		break-inside: avoid;
	}
}

.c-megamenu__item--overview {
	@include bp($megamenu-bp) {
		display: none;
	}
}

.c-navigation__item {
	&.c-navigation__item--megamenu {
		@include bp($megamenu-bp) {
			position: static;
		}
	}
}

.c-megamenu__heading {
	position: relative;
}

.c-megamenu__link {
	color: $black;
	display: block;
	padding: $tsu 0;
	position: relative;

	&:before {
		display: none;
	}

	@include bp($megamenu-bp) {
		@include font-base-20;
		padding: $tsu $ssu $tsu 0;

		&::before {
			@include abs-center-v;
			right: $bsu;
			display: block;
			color: $brand--alt;
			opacity: 0;
			transition: all 0.3s ease;
		}

		&:hover {
			color: var(--link--hover);
		}

		&:hover::before {
			right: $ssu;
			opacity: 1;
		}

		&::after {
			display: none;
			content: "";
			width: 0;
			display: block;
			opacity: 0;
			transition: all 0.3s ease;
			height: 2px;
			margin-top: $tsu;
		}

		&:focus::after,
		&:hover::after {
			width: calc(100% + #{$bsu});
			opacity: 1;
		}
	}
}

.c-megamenu__link-icon {
	&::before {
		@include font-icon;
		content: $iconf-arrow-right;
	}

	display: none;

	@include bp($megamenu-bp) {
		position: absolute;
		right: 0;
		opacity: 0;
		transition: all 0.3s ease;
		display: block;
		top: 45%;
		transform: translateY(-50%);

		.c-megamenu__link:hover &,
		.c-megamenu__link:focus & {
			opacity: 1;
		}
	}
}

.c-megamenu__promo-list {
	display: none;

	@include bp($megamenu-bp) {
		// Pretend the last column has a gap as well, divide the full width, then account for that extra gap
		$megamenu__column-width: calc(((100% + #{$megamenu__column-gap}) / #{$megamenu__column-number}) - #{$megamenu__column-gap});
		$megamenu__column-width-vars: calc(((100% + var(--megamenu-column-gap)) / #{$megamenu__column-number}) - #{$megamenu__column-gap});

		// Difference between normal and reduced number of columns
		$megamenu__column-number--promos: $megamenu__column-number - $megamenu__column-number--with-promos;
		$megamenu__column-number--promos-vars: var(--megamenu-number-promos, #{$megamenu__column-number - $megamenu__column-number--with-promos});

		// Column width for each column space taken, plus the appropriate number of gaps between columns
		$megamenu__promo-width: calc((#{$megamenu__column-width} * #{$megamenu__column-number--promos}) + calc(#{$megamenu__column-gap} * calc(#{$megamenu__column-number--promos} - 1)));
		$megamenu__promo-width-vars: calc((#{$megamenu__column-width-vars} * #{$megamenu__column-number--promos-vars}) + calc(#{$megamenu__column-gap} * calc(#{$megamenu__column-number--promos-vars} - 1)));

		width: $megamenu__promo-width;
		width: $megamenu__promo-width-vars;
		flex-shrink: 0;

		@include flex-extend;
		justify-content: flex-end;
	}
}

.c-megamenu__promo-item {
	@include link-transition;

	&:hover {
		//opacity: .7;
	}

	@include bp($megamenu-bp) {
		width: calc(((100% + #{$ssu}) / 2) - #{$ssu});
		width: calc(((100% + #{$ssu}) / var(--megamenu-number-promos, 2)) - #{$ssu});
		margin-left: ($tsu * 1.5);
		height: 317px;

		&:first-child {
			margin-left: 0;
		}
	}

	@include bp("extra-large") {
		width: calc(((100% + #{$ssu}) / 2) - #{$lsu});
		width: calc(((100% + #{$ssu}) / var(--megamenu-number-promos, 2)) - #{$lsu});
	}
}

.c-pod, .c-pod-noimage {
	.c-megamenu__promo-item & {
		height: 317px;
	}
}

.c-megamenu__promo-image {
	overflow: hidden;
}

.c-megamenu__promo-item {
	.c-pod-flip__front {
		background: linear-gradient(to top, rgba($black, 1), rgba($black,0.5) 30%, transparent 100%);
	}

	&:hover {
		.c-pod-flip__front {
			background: transparent;

		}
	}
}

.c-megamenu__promo-title {
	@include font-heading-20b;
	display: block;
	margin: $ssu 0 0;

	.c-megamenu__promo-item:hover .c-pod-flip__front & {
		opacity: 0;
	}

	display: flex;
	align-items: flex-end;

	&::after {
		@include font-icon;
		content: $iconf-arrow-right;
		display: inline-block;
		margin-left: $ssu;
		font-size: 20px;
	}


	.c-pod-flip__front & {
		&::after {
			visibility: hidden;
			opacity: 0;
		}
	}
}