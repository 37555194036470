// CONTROL.INVESTOR-LISTING

$investor-listing-bp: "medium";

.c-investor-listing {
	@include control;
}
// Second block to ensure order is maintained when compiling
.c-investor-listing {
	.l-page__main-controls &:first-child {
		margin-top: 0;
	}
}

.c-investor-listing__layout {
	@include layout-wrapper;
}

.c-investor-listing__header {
	display: block;
	padding: $bsu 0;
	background: $grey--light;
	margin-bottom: $bsu;

	@include bp("large") {
		padding: ($bsu * 1.5) 0;
	}
}

.c-investor-listing__header-layout {
	@include layout-wrapper;
}

.c-investor-listing__introduction-layout {
	display: flex;
	align-items: flex-start;
	margin-left: -$bsu;
	margin-top: 0;
	flex-direction: column;

	@include bp($investor-listing-bp) {
		flex-direction: row;
	}
}

.c-investor-listing__introduction {
	@include font-base-16;
	padding-left: $bsu;
	padding-top: 0;
	width: 100%;

	@include bp($investor-listing-bp) {
		@include font-base-18;
		width: 50%;
	}
}

.c-investor-listing__filter-controls {
	flex-direction: column;

	@include bp("medium") {
		padding-top: 0;
	}
	@include bp($investor-listing-bp) {
		flex-direction: row;
	}
}

.c-investor-listing__filter-controls {
	.c-filter__search {
		width: 100%;
		margin-bottom: ($tsu * 1.5);

		@include bp($investor-listing-bp) {
			width: auto;
			margin-right: $tsu;
			margin-bottom: 0;
		}
	}
}

/////////////
// Listing //
/////////////
.c-investor-listing__listing {
	@include control;
}

.c-investor-listing__list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;

	margin-left: -$ssu;
	margin-top: -$ssu;
	@include bp($investor-listing-bp) {
		margin-left: -$bsu;
		margin-top: -$bsu;
	}
}

.c-investor-listing__list-item {
	padding-left: $ssu;
	padding-top: $ssu;
	@include bp($investor-listing-bp) {
		padding-left: $bsu;
		padding-top: $bsu;
	}

	width: percentage((1 / 2));
	@include bp("medium") {
		width: percentage((1 / 3));
	}
	@include bp("large") {
		width: percentage((1 / 4));
	}
}

.c-investor-listing__pod {
	height: 100%;
	display: flex;
	flex-direction: column;

	padding: $tsu 0;
	@include bp($investor-listing-bp) {
		padding: $ssu 0;
	}
	border-bottom: 2px solid $grey--lightest;
}

.c-investor-listing__pod-figure {
	margin-bottom: $bsu;
	padding: 0 $bsu;

	@include figure;
}

.c-investor-listing__pod-image {
	display: block;
	width: 100%;
	height: 100%;

	.c-investor-listing & {
		// Extra specificity to override theme default
		background-color: transparent;
		padding-bottom: percentage((5 / 8));
	}

	img {
		object-fit: scale-down;
		&[src$=".svg"] {
			object-fit: contain;
		}
	}
}

.c-investor-listing__pod-body {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	text-align: center;
}

.c-investor-listing__pod-heading {
	display: block;
	margin-bottom: $ssu;

	@include font-heading-18b;
	@include bp($investor-listing-bp) {
		@include font-heading-24b;
	}
}

.c-investor-listing__pod-cta {
	@include font-base-14mu;
	@include bp($investor-listing-bp) {
		@include font-base-16mu;
	}
}
