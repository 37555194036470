.c-ced-listing {
	@include control;

	--filter-highlight: var(--supporting-2-a);

	padding-bottom: $bsu;
	padding-bottom: var(--control-gap-bottom);

	.c-pagination:last-child {
		// Adding bottom padding means pagination bottom margin won't collapse
		margin-bottom: 0;
	}

	border-bottom: 1px solid $grey;
}
// Second block to ensure order is maintained when compiling
.c-ced-listing {
	margin-top: 0;
}

.c-ced-listing__layout {
	@include layout-wrapper;
}

////////////
// FILTER //
////////////
.c-ced-listing__filter-background {
	background: $grey--light;
	overflow: hidden; // Prevent margins overflowing

	padding-bottom: $bsu;
}

.c-ced-listing__filter-introduction-layout {
	display: flex;
	justify-content: space-between;
	margin-left: -$bsu;
	margin-top: $bsu;
	flex-direction: column;

	@include bp("medium") {
		flex-direction: row;
	}
}

.c-ced-listing__filter-title {
	flex-shrink: 0;

	padding-left: $bsu;

	@include bp-below("medium") {
		@include font-heading-24b;
		margin-bottom: $bsu;
	}
	@include bp("medium") {
		@include font-heading-36b;
	}
}

.c-ced-listing__filter-controls {
	padding-top: 0;
}

//////////
// LIST //
//////////
.c-ced-listing__list-background {
	// Force a little bit of overlap to prevent aliasing when zooming in or out
	margin-top: -2px;
	padding-top: 2px;
	background: linear-gradient(180deg, $grey--light 0%, $grey--light 200px, transparent 200px, transparent 100%);
}

.c-ced-listing__list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;

	margin-left: -$ssu;
	margin-top: -$bsu;
}

.c-ced-listing__list-item {
	padding-left: $ssu;
	padding-top: $bsu;

	width: 100%;
	@include bp("medium") {
		width: 50%;
	}
	@include bp("large") {
		width: percentage((1 / 3));
	}

	// Promo
	.c-ced-listing__list--has-promo &:nth-child(7) {
		width: 100%;
		.c-cta {
			margin: 0;
			padding: 0;
		}
	}
}

@supports (display: grid) {
	.c-ced-listing__list {
		display: grid;

		margin: 0;
		grid-gap: $bsu $ssu;

		grid-template-columns: 100%;
		@include bp("medium") {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
		@include bp("large") {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}
	}

	.c-ced-listing__list-item {
		padding: 0;
		width: 100%;

		// Promo
		.c-ced-listing__list--has-promo &:nth-child(7) {
			width: 100%;

			@include bp("medium") {
				grid-column-end: span 2;
			}
			@include bp("large") {
				grid-column-end: span 3;
			}
		}
	}
}

//////////////
// LINK POD //
//////////////
.c-ced-listing__link-pod {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;

	padding: $hsu $lsu $lsu;

	height: 100%;

	background: $white;
}

.c-ced-listing__link-pod-title {
	display: block;

	@include font-heading-28b;
}

.c-ced-listing__link-pod-text {
	display: block;
	margin-top: $lsu;

	@include font-base-16sb;

	&::after {
		@include font-icon;
		content: $iconf-arrow-right;

		display: inline-block;
		vertical-align: middle;
		margin-left: $tsu;

		color: var(--secondary-a);
	}
}
