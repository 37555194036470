$news-bp: "medium";

.c-tools-resources {
	@include control;
}

.c-tools-resources__header {
	display: block;
	padding: $bsu 0 $bsu;

	@include bp("large") {
		padding: ($lsu + $ssu) 0 $bsu;
	}

	background: $grey--light;
}

.c-tools-resources__header-layout {
	@include layout-wrapper;
}


.c-tools-resources__layout {
	@include layout-wrapper;
}


.c-tools-resources-listing__layout {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;

	@include bp("medium") {
		flex-direction: row;
	}
}

.c-tools-resources-listing__heading {
	@include font-heading-28b;

	@include bp-below("medium") {
		@include font-heading-24b;
		margin-bottom: $ssu;
	}
}

.c-tools-resources-listing__no-results {
	@include body-text;

	// Ensure it appears above the background
	position: relative;
}

.c-tools-resources__filter-controls {
	display: flex;
	justify-content: flex-end;

	@include bp-below("medium") {
		width: 100%;
	}
}



.c-tools-resources__filter-toggle {
	@include button("medium");
	@include font-base-16m;
	display: flex;
	white-space: nowrap;
	align-items: center;
	border-radius: 2px;
	display: flex;
	justify-content: center;
	align-content: center;

	@include bp-below("medium") {
		width: 100%;
	}
}

.c-tools-resources__filter-toggle-icon {
	&::after {
		display: block;
		margin-left: $ssu;

		@include font-icon;
		content: $iconf-close;
		font-size: 24px;

		.c-tools-resources__filter-toggle[aria-expanded="false"] & {
			content: $iconf-filter;
		}

		.c-tools-resources__filter-mobile & {
			font-size: 16px;
		}
	}
}

.c-tools-resources__filter-toggle-mobile-wrapper {
	@include font-base-16;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: $ssu;

	@include bp("medium") {
		display: none;
	}
}

.c-tools-resources__filter-toggle-mobile {
	border: 0;
}

.c-tools-resources-listing__filter {

	@include bp-below("medium") {
		position: fixed;
		top: 0;
		z-index: 3;
		height: 100%;
		width: 100%;
		background-color: $grey--light;
		left: 0;
		transform: rotateY(0);
		transition: .3s;
		transform-style: preserve-3d;
		padding: 0 $ssu;
	}

	&[aria-expanded="false"] {
		display: none;

		@include bp-below("medium") {
			transform: rotateY( -90deg);
			transition: .3s;
			transform-style: preserve-3d;
			position: fixed;
			top: 0;
			visibility: hidden;
			opacity: 0;
			overflow: hidden;
			display: block;
		}
	}
}

.c-tools-resources__filter-section {
	margin: $bsu 0;
}

.c-tools-resources__filter-title {
	display: block;
	margin-bottom: $ssu;

	@include font-heading-18b;
}

.c-tools-resources__filter-item-list {
	@include list-reset;
	display: flex;
	margin-left: -$tsu;
	margin-top: -$tsu;
	flex-wrap: wrap;

	@supports (background-color: var(--secondary-a)) {
		margin-left: 0;
		position: relative;

		&::before {
			content: "";
			position: absolute;
			top: $tsu;
			bottom: 0;
			left: 0;
			width: 2px;
			background-color: var(--secondary-a);
		}
	}
}

.c-tools-resources__filter-item {
	padding-left: $tsu;
	padding-top: $tsu;
}

.c-tools-resources__filter-item-button {
	display: block;
	padding: 0 $ssu;
	border-radius: 40px;

	@include font-base-16m;
	height: 40px;
	line-height: 40px;
	white-space: nowrap;

	@include link-transition;
}


//////////
// LIST //
//////////
.c-tools-resources-listing__list-background {
	position: relative;

	&::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: -1px; // Ensure no rounding/anti-aliasing issues can occur
		height: 101px;
		background: $grey--light;
	}
}

.c-tools-resources-listing__layout-wrapper {
	@include layout-wrapper;
}

.c-tools-resources-listing__list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;
	margin-left: -$ssu;
	margin-top: -$ssu;
}

@supports (display: grid) {
	.c-tools-resources-listing__list {
		display: grid;
		margin: 0;
		grid-gap: $ssu;
		grid-template-columns: 100%;

		@include bp("medium") {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		@include bp("large") {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}
	}

	.c-pods__item {
		.c-tools-resources-listing__list & {
			padding-left: 0;
			&:nth-child(-n+2) {
				@include bp("large") {
					grid-column: span 2;
				}
			}
		}
	}
}

