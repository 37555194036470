// COMPONENT.HERO

$hero-bp: "large";

.c-hero {
	position: relative;
	margin-bottom: 0;

	.c-content-type-naturelandingpage &,
	.l-page__header-listing & {
		margin-bottom: 0;
	}
}

.c-hero--standard {
	position: relative;
	background-color: $grey--lightest;
	margin-bottom: 0;
}

.c-hero--video {
	margin-bottom: 0;

	.c-hero__heading {
		margin-bottom: 20px;
	}

	@include bp('large') {
		.c-hero__heading {
			margin-bottom: 40px;
		}
	}
	height: 300px;
	.c-hero__figure {
		height: 100%;
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	@include bp('large') {
		height: 400px;
	}
}

.c-hero__figure {
	@include figure;
}

///////////
// IMAGE //
///////////

.c-hero__image {
}

.c-hero__image--mobile {
	@include bp($hero-bp) {
		display: none;
	}
}

.c-hero__image--desktop {
	@include bp-below($hero-bp) {
		display: none;
	}
}

.c-hero__heading {
	//position: absolute;
	//top: calc(50% + 32px);
	//left: 50%;
	//transform: translate(-50%, -50%);
	//text-align: center;
	@include font-heading-28b;
	color: $white;
	margin-bottom: $bsu;

	@include bp("medium") {
		@include font-heading-40b;
		margin-bottom: $ssu;
	}

	@include bp("large") {
		@include font-heading-52b;
		margin-bottom: $ssu;
	}

	@include bp("extra-large") {
		@include font-heading-72b;
		margin-bottom: $bsu;
	}
	// Windows Scaling 125%
	@media (-webkit-device-pixel-ratio: 1.25) {
		@include font-heading-52b;
	}
	// Windows Scaling 150%
	@media (-webkit-device-pixel-ratio: 1.50) {
		@include font-heading-52b;
	}
}

.c-hero__introduction {
	@include bp-below("large") {
		display: none;
	}

	@include bp("large") {
		display: block;
		margin-bottom: $ssu;
	}

	@include font-base-20b;
	color: $white;
	margin-bottom: $bsu;

	@include bp("extra-large") {
		margin-bottom: $bsu;
	}
}

.c-hero__content-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	&::after {
		content: "";
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		background: linear-gradient(to bottom, rgba($black, 0), rgba($black, 0.73) 100%);
	}
}

.c-hero__body-wrapper {
	@include layout-wrapper;
	display: flex;
	align-items: center;
	height: 100%;
	position: relative;
	z-index: 1;
}

.c-hero__body--narrow {
	width: 100%;

	@include bp("large") {
		max-width: 55%;
	}
}

///////////
// VIDEO //
///////////
.c-hero__video {
	display: none;

	@include bp("medium") {
		display: block;
		width: 100%;
		max-height: 80vh;
		object-fit: cover;

		~ .c-hero__image {
			display: none;
		}
	}
}

/////////////
// PRIMARY //
/////////////

.c-hero__primary {
	margin: 0 0 $bsu 0;
	position: relative;
	z-index: 1;

	@include flex-extend;
	flex-direction: column-reverse;

	@include bp($hero-bp) {
		flex-direction: row;
		align-items: stretch;
	}

	.c-hero--standard & {
		background-color: $grey--lightest;
		background-image: url("/content/images/interface/bg/white-circles-pattern-quarter-left.svg");
		background-position: bottom left;
		background-repeat: no-repeat;
		margin-bottom: 0;


		@include bp("large") {
			margin-bottom: 0;
		}

		@include bp-below("large") {
			background-size: 200px;
		}
	}

	.c-hero__body {
		@include flex-extend;
		align-items: center;
		padding: $bsu;

		@include bp($hero-bp) {
			width: 100%;
			min-height: 200px;

			.c-hero--has-image & {
				width: 50%;
				min-height: 400px;
				//padding-left: 0;
				//padding-right: 0;
			}
			// Offset from image
			margin-bottom: $lsu;
		}
	}

	.c-hero__body-content {
		flex-direction: column;
		padding: $bsu;
		padding: $bsu var(--layout-gutter-base-left) $bsu var(--layout-gutter-base-right);

		@include bp-below($hero-bp) {
			//padding: var(--layout-gutter-base-left);
		}

		@include bp($hero-bp) {
			@include layout-wrapper;

			.c-hero--has-image & {
				@include layout-split(left, 50%);
				max-width: none;
				padding: var(--layout-gutter-base-right);
			}
		}
	}

	.c-hero__back-link {
		display: block;
		@include link-transition;

		@include bp-below($layout-wrapper-max-width) {
			@include font-base-16sb;
			margin-bottom: $bsu;
		}

		@include bp($layout-wrapper-max-width) {
			@include font-base-20sb;
			margin-bottom: $lsu;
		}

		&::before {
			@include font-icon;
			content: $iconf-arrow-left;
			display: inline-block;
			vertical-align: center;
			margin-right: $tsu;
			color: var(--secondary-a);
		}


		@include bp($layout-wrapper-max-width) {
			transform: translateX(calc(-1em - #{$tsu}));
		}
	}

	.c-hero__title {
		@include font-heading-36b;
		position: relative;
		z-index: 2;
		margin-bottom: $ssu;

		@include bp("medium") {
			margin-bottom: $bsu;
		}

		@include bp($hero-bp) {
			font-size: 60px;
			line-height: 1;
			margin-bottom: $lsu;
		}
	}

	.c-hero__text {
		@include font-base-18;

		@include bp($hero-bp) {
			flex-grow: 1;
		}

		a {
			@include link-brand;
		}
	}

	.c-hero__date {
		@include font-base-12;
		margin-bottom: 2em;
	}

	.c-hero__scroll-icon {
		margin-top: $lsu;
		color: $red;
		text-align: center;
		font-size: 24px;
		display: none;

		@include bp($hero-bp) {
			display: block;
		}
	}

	.c-hero__figure {
		@include figure;
		flex-grow: 1;
		position: relative;
		overflow: hidden;
		max-height: 80vh;
		max-height: unquote("min(400px, 80vh)");

		@include bp($hero-bp) {
			max-height: none;
		}
	}

	.c-hero__image {
		display: block;
		width: 100%;

		@include bp($hero-bp) {
			height: 100%;
			padding: 0 !important;
			object-fit: cover;
		}
	}
}

///////////////
// SECONDARY //
///////////////
.c-hero__secondary {
	margin: ($bsu * 3) 0 0; // Match .o-layout-page

	.c-hero__secondary-title {
		width: 100%;
		text-align: center;
	}

	.c-hero__secondary-title-text {
		display: block;
		color: $brand;
		margin-bottom: $bsu;

		@include bp($hero-bp) {
			padding: 0 20px;
			margin-bottom: 0;
		}
	}

	.c-hero__secondary-introduction {
		@include font-base-18;
		flex: 0 1 auto;

		@include bp($hero-bp) {
			margin-top: 4px; // Align better with secondary title
			margin-left: 40px;
		}
	}
}


///////////////
// HOME //
///////////////
.c-hero__home {
	overflow: hidden;

	@include bp("large") {
		min-height: 750px;
	}

	.c-hero__figure {
		position: absolute;
		width: 100%;
		height: 100%;

		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			bottom: 0;
			background: rgba($black, 0.1);
			z-index: 1;
		}


		@include bp("medium") {
			&::before {
				background: linear-gradient(to bottom, $black 0, rgba($black, 0.3) 20%, transparent 100%);
			}
		}
	}

	.c-hero__content-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;
		top: 0;
		left: 0;
		padding-top: $header-height + $bsu;
		padding-top: calc(var(--header-height) + #{$bsu});
		display: flex;

		@include bp("large") {
			min-height: 750px;
		}

		&::after {
			display: none;
		}
	}

	.c-hero__body-wrapper {
		width: 100%;
		height: auto;
		display: flex;
		margin: 0 auto;
		flex-grow: 1;
		flex-direction: column;
		justify-content: flex-end;
		padding: 0;
		align-items: flex-start;

		@include bp("large") {
			padding: 0 $bsu;
		}
	}

	.c-hero__body {
		max-width: 100%;
		padding: 0 $bsu;
		//display: flex;
		flex-direction: column;
		justify-content: center;

		@include bp("large") {
			max-width: 70%;
			padding: 0;
		}

		&.no-hero-pods {
			margin-bottom: auto;
		}
	}

	.c-hero__image {
		min-height: 100vh;
		height: 100%;

		.o-fit__image {
			object-position: center;
		}
	}
}

.c-hero__cta {
	@include button("small");
	background-color: transparent;
	border: 1px solid var(--primary-b);
	color: var(--primary-b);
	border-radius: 2px;
	//padding-top: ($ssu * 1.5);
	//padding-bottom: ($ssu * 1.5);

	@include bp("medium") {
		@include button("medium");
		background-color: transparent;
		border: 1px solid var(--primary-b);
	}

	&:hover,
	&:focus {
		border-color: var(--link--hover);
		background-color: var(--link--hover);
	}
}


.c-hero__pods {
	display: grid;
	grid-gap: 2px;
	grid-template-columns: repeat(1, 1fr);
	margin-top: $header-height;

	@include bp("medium") {
		grid-template-columns: repeat(2, 1fr);
	}

	@include bp("large") {
		$header-height: 80px;
		margin-top: $header-height;
		max-width: 70%;
	}

	@include bp("extra-large") {
		$header-height: 120px;
		margin-top: $header-height;
	}
}

.c-hero__pod {
	display: flex;
	height: 100%;

	&:hover,
	&:focus {


		.c-pod-flip__front {
			opacity: 0;
		}

		.c-pod-flip__back {
			opacity: 1;
			transform: rotateY(0);
		}
	}
}

.c-hero__pod-item-link {
	position: relative;
	display: block;
	width: 100%;
}

.c-pod-flip__inner {
	.c-hero__pod & {
		padding: ($ssu * 1.5) $bsu;

		@include bp("large") {
			padding: $bsu ($bsu + $ssu);
		}
	}
}

.c-pod-flip__front {
	background: linear-gradient(to top, rgba($black, 1), rgba($black,0.5) 30%, transparent 100%);

	.c-hero__pod & {
		background: rgba($primary-a,0.88);
	}
}

.c-pod-flip__back {
	.c-hero__pod & {
		background-color: rgba($secondary-a,0.88);
	}
}

.c-hero__pod-item-heading {
	@include font-heading-24b;
	margin-bottom: $tsu;
	display: flex;
	align-items: center;

	@include bp("large") {
		margin-bottom: ($bsu - 5px);
	}

	@include bp("extra-large") {
		@include font-heading-36b;
	}
}

.c-hero__pod-item-link-icon {
	font-size: 20px;
	margin-top: auto;
	margin-left: auto;
}

.c-hero__pod-item-text {
	@include font-base-14;
	margin-bottom: 0;

	@include bp("large") {
		@include font-base-18;
	}
}
