$icomoon-font-family: "font-icons" !default;
$icomoon-font-path: "fonts" !default;

$iconf-Quote: "\e900";
$iconf-search: "\1f50d";
$iconf-plus: "\2b";
$iconf-minus: "\2d";
$iconf-close: "\2718";
$iconf-tick: "\2714";
$iconf-arrow-down-chevron: "\2c5";
$iconf-arrow-left-chevron: "\2c2";
$iconf-arrow-right-chevron: "\2c3";
$iconf-arrow-up-chevron: "\2c4";
$iconf-arrow-down: "\2193";
$iconf-arrow-left: "\2190";
$iconf-arrow-right: "\2192";
$iconf-arrow-up: "\2191";
$iconf-link: "\1f517";
$iconf-external: "\e90e";
$iconf-address: "\1f4cd";
$iconf-alert: "\21";
$iconf-download: "\e911";
$iconf-calendar: "\1f4c5";
$iconf-camera: "\1f4f7";
$iconf-ce-regenerating: "\1f331";
$iconf-ce-recycling: "\267c";
$iconf-ce-redesigning: "\1f4a1";
$iconf-ce-reducing: "\21e9";
$iconf-ce-extending: "\1f9f0";
$iconf-ce-sharing: "\1f465";
$iconf-enlarge: "\e91a";
$iconf-chat: "\1f5e9";
$iconf-click: "\e91c";
$iconf-email: "\2709";
$iconf-fax: "\1f5b7";
$iconf-filter: "\e91f";
$iconf-flickr: "\e920";
$iconf-home: "\1f3e0";
$iconf-instagram: "\e922";
$iconf-map: "\e923";
$iconf-mobile: "\1f4f1";
$iconf-phone: "\1f4de";
$iconf-photo: "\1f5bc";
$iconf-play: "\25b6";
$iconf-print: "\1f5a8";
$iconf-reload: "\1f5d8";
$iconf-share: "\e92a";
$iconf-snapchat: "\e92b";
$iconf-tripadvisor: "\e92c";
$iconf-tumblr: "\e92d";
$iconf-linkedin: "\e92e";
$iconf-twitter: "\e92f";
$iconf-video: "\1f4f9";
$iconf-wechat: "\e931";
$iconf-weibo: "\e932";
$iconf-pinterest: "\e933";
$iconf-youku: "\e934";
$iconf-facebook: "\e935";
$iconf-youtube: "\e936";
$iconf-blogger: "\e937";

