// COMPONENT.CED-POD

.c-ced-pod {
	display: flex;
	flex-direction: column;

	height: 100%;
}

.c-ced-pod__link {
	display: flex;
	flex-direction: column;

	flex-grow: 1;

	&:hover,
	&:focus {
		.c-ced-pod__image img {
			transform: scale(1.2);
		}
	}

	&:not(.c-ced-pod--premium) {
		&:hover,
		&:focus {
			.c-ced-pod__title {
				color: var(--supporting-2-a);
			}
		}
	}
}

.c-ced-pod__figure {
	@include figure;
	margin: 0 15%;
}

.c-ced-pod__image {
	border-radius: 1000px; // Large enough to ensure it becomes a circle
	overflow: hidden;

	.c-ced-pod & {
		// Extra specificity to override default theme
		// Remove background colour as it can show through the aliasing on rounded corners
		background-color: transparent;
	}

	img {
		@include link-transition;
	}
}

.c-ced-pod__body,
.c-ced-pod__icon-container {
	background: $white;
	background: var(--control-bg-offset, #{$white});

	.c-ced-pod--premium & {
		background: $brand;
		background: var(--primary-a);
		color: $white;
		color: var(--primary-b);
	}

	padding: $ssu;

	text-align: center;
}

.c-ced-pod__body {
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	margin-top: (50% - 15%);
	.c-ced-pod__figure ~ & {
		margin-top: (-1 * (50% - 15%));
		padding-top: (50% - 15%);
	}

	> :last-child {
		margin-bottom: 0;
	}
}

.c-ced-pod__title {
	margin-top: $ssu;
	display: block;
	margin-bottom: $ssu;

	@include font-heading-24b;

	@include link-transition;
}

.c-ced-pod__abstract {
	flex-grow: 1;

	display: block;
	margin-bottom: $ssu;

	@include font-base-16;
}

.c-ced-pod__tags {
	display: block;
	margin-bottom: $bsu;
}

.c-ced-pod__tags-list {
	@include font-base-12mu;
}

.c-ced-pod__tags-more {
	@include font-base-12m;
	font-style: italic;
}

.c-ced-pod__icon-container {}

.c-ced-pod__icon-list {
	@include list-reset;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	margin-left: -$tsu;
	margin-top: -$tsu;
}

.c-ced-pod__icon-item {
	padding-left: $tsu;
	padding-top: $tsu;
}

.c-ced-pod__icon {
	position: relative;
}

.c-ced-pod__icon-tooltip {
	display: block;
	text-align: left;
}

@mixin ced-pod-icon-active {
	color: var(--supporting-2-b);
	&::after {
		opacity: 1;
		transform: scale(1);
	}
}

$ced-pod-icon-size: 32px;
$ced-pod-hover-size: 48px;

.c-ced-pod__icon-content {
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: $ced-pod-icon-size;
	width: #{$ced-pod-hover-size / $ced-pod-icon-size}em;
	height: #{$ced-pod-hover-size / $ced-pod-icon-size}em;

	@include link-transition;
	position: relative;
	isolation: isolate;
	&::after {
		content: "";

		background: var(--supporting-2-a);
		opacity: 0;
		transform: scale(0);

		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 100%;

		@include link-transition;
	}

	.c-ced-pod__icon-tooltip & {
		cursor: pointer;
	}

	// Separate rules to support old browsers that
	// think :focus-within is not a valid pseudoclass.
	.c-ced-pod__icon-tooltip:hover &,
	.c-ced-pod__icon-tooltip:focus & {
		@include ced-pod-icon-active;
	}
	.c-ced-pod__icon-tooltip:focus-within & {
		@include ced-pod-icon-active;
	}
}
