.c-page-header {
	padding: $lsu 0;
}

.c-page-header__layout {
	@include layout-wrapper;
}

.c-page-header__lede {
	@include font-base-20-tall;
	margin: 0 auto;

	> *:last-child {
		margin-bottom: 0;
	}

	a {
		@include link-brand;
		@include font-base-20sb;
	}
}

.c-page-header__date {
	@include font-base-12;
	margin-bottom: 2em;
}
