﻿// CONTROL.FILTER(in Listing)

@use "sass:math";

$filter-bp: "large";

.c-filter__control {
	@include control;
}

.c-filter__layout {
	@include layout-wrapper;
}

.c-filter {
	margin-bottom: $bsu;
}

.c-filter__form {
}

.c-filter__field {
	margin-bottom: $bsu;

	@include bp($filter-bp) {
		display: inline-flex;
		align-items: center;
	}
}

.c-filter__label {
	display: block;
	@include font-base-14b;
	margin-bottom: $tsu;

	@include bp($filter-bp) {
		margin-bottom: 0;
		margin-right: $ssu;
	}
}

.c-filter__dropdown {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	width: 100%;

	select {
		@include ui-border("all");
		padding: $ssu $lsu $ssu $ssu;
		margin: 0;
		width: 100%;
		background: $grey--light;
		/*Hiding the select arrow for firefox*/
		-moz-appearance: none;
		/*Hiding the select arrow for chrome*/
		-webkit-appearance: none;
		/*Hiding the select arrow default implementation*/
		appearance: none;

		&::-ms-expand {
			display: none;
		}
	}

	&::before {
		@include font-icon;
		position: absolute;
		display: block;
		content: $iconf-arrow-down;
		right: $bsu;
		top: 50%;
		transform: translateY(-50%);
		color: $brand;
		pointer-events: none;
	}

	@include bp($filter-bp) {
		width: auto;
		min-width: 200px;
		margin-right: $bsu;
	}
}

.c-filter__input-wrap {
	position: relative;
}

.c-filter__input {
	@include ui-border("all");
	padding: $ssu $lsu $ssu $ssu;
	margin: 0;
	width: 100%;
	background: $grey--light;
}

	.c-filter__input[list] {
		// Using dataList
		padding-right: $ssu;

		&::-webkit-calendar-picker-indicator {
			// Hide arrow in webkit browsers
			display: none;
		}
	}

.c-filter__input-icon {
	position: absolute;
	right: $bsu;
	top: 50%;
	transform: translateY(-50%);
	color: $brand;
	pointer-events: none;
}

.c-filter__item {
	padding-left: $tsu;
	padding-top: $tsu;
}

.c-filter__item-label {
	@include flex-extend;
	@include fake-input__wrapper;

	align-items: center;
	cursor: pointer;
}

.c-filter__item-input {
	margin-right: $ssu;
}

.c-filter__item-label-text {
	@include font-base-14;
}

.c-filter__expand {
	.c-filter__expand-body {
		display: none;
	}

	.c-filter__heading {
		@include font-heading-24b;
		cursor: pointer;
	}

	.c-filter__heading--mobile {
		@include bp($filter-bp) {
			display: none;
		}
	}
	.c-filter__heading--desktop {
		@include bp-below($filter-bp) {
			display: none;
		}
	}

	&[aria-expanded="true"] {
		.c-filter__expand-body {
			display: block;
		}

		.c-filter__heading {
			margin-bottom: 0.7em;
		}
	}

	@include bp($filter-bp) {
		.c-filter__expand-body {
			display: block;
		}

		.c-filter__heading {
			margin-bottom: 0.7em;
			cursor: default;
		}
	}
}

// filter in sidebar
.c-filter__aside {
	@include ui-border("all");

	.c-filter__form {
		padding: $bsu;
	}

	.c-filter__field {
		display: block;
		width: 100%;
	}

	.c-filter__dropdown {
		margin-right: 0;
		width: 100%;
	}

	.c-filter__label {
		margin-bottom: ($tsu / 2);
	}

	.c-filter__label-inline {
		display: inline-block;
		margin-top: $ssu;
		vertical-align: middle;
		@include font-base-14;
		width: 50px;
	}

		.c-filter__label-inline + .c-filter__input-wrap {
			width: calc(100% - 55px);
			display: inline-block;
			vertical-align: middle;
			margin-top: $ssu;
		}

	.c-filter__controls {
		@include flex-extend;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;
	}
}

.c-filter__submit {
	@include button;
}

.c-filter__reset {
	@include button;
	@include button-reset;
}

//////////////////////////
//  Filter List Search  //
/////////////////////////

.c-filter__search{}

.c-filter__input-wrapper {
	position: relative;
	background: $white;
}

.c-filter__input {
	.c-filter__search & {
		@include ui-border("all");
		padding: 17px  ($ssu * 3) 17px ($ssu * 1.5);
		margin: 0;
		width: 100%;
		background: $white;
	}
}

.c-filter__search-button {
	@include button("small");
	border: 0;
	position: absolute;
	top: 0;
	right: 0;
	padding: 17px $ssu;
	background-color: transparent;
	font-size: 20px;
	display: flex;
}
///////////////////
//  Filter List  //
//////////////////
.c-filter__layout {
	@include layout-wrapper;
}

.c-filter__header {
	display: block;
	padding: $bsu 0 $bsu;

	@include bp("large") {
		padding: $hsu 0 $bsu;
	}

	background: $grey--light;
}

.c-filter__header-layout {
	@include layout-wrapper;
}

.c-filter__heading {
	@include font-heading-28b;

	@include bp("large") {
		@include font-heading-52b;
	}
}

.c-filter__introduction-layout {
	display: flex;
	justify-items: space-between;
	margin-left: -$bsu;
	margin-top: $ssu;
	flex-direction: column;

	@include bp("large") {
		margin-top: $bsu;
		flex-direction: row;
	}
}

.c-filter__introduction {
	@include font-base-16;
	padding-left: $bsu;
	width: 100%;

	@include bp("large") {
		@include font-base-18;
		width: 50%;
		padding-top: $bsu;
	}

	> :last-child {
		margin-bottom: 0;
	}

	a {
		@include link-brand;
		@include font-base-18sb;
	}
}

.c-filter__introduction-heading {
	padding-left: $bsu;
	width: 100%;

	@include bp("large") {
		width: 50%;
		padding-top: $bsu;
	}

	> :last-child {
		margin-bottom: 0;
	}
}

.c-filter__controls {
	padding-left: $bsu;
	padding-top: $bsu;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	width: 100%;
	flex-direction: column;

	@include bp("medium") {
		flex-direction: row;
	}

	@include bp("large") {
		width: 50%;
		flex-direction: column;
	}

	@include bp("extra-large") {
		flex-direction: row;
	}
}

.c-filter__button-wrapper {
	background-color: $white;
	display: flex;
	border-radius: 2px;
	width: 100%;
	margin-bottom: ($tsu * 1.5);

	@include bp("medium") {
		margin-right: $tsu;
		margin-bottom: 0;
	}

	@include bp("large") {
		margin-right: 0;
		margin-bottom: ($tsu * 1.5);
	}

	@include bp("extra-large") {
		width: auto;
		margin-right: $tsu;
		margin-bottom: 0;
	}
}

.c-filter__button {
	@include button("medium");
	@include font-base-14;
	display: flex;
	white-space: nowrap;
	align-items: center;
	@include link-transition;
	width: 50%;

	@include bp("medium") {
		@include font-base-16m;
	}

	@include bp("extra-large") {
		width: auto;
	}
}

.c-filter__button-checkbox {
	@include circle(25px);
	flex-shrink: 0;
	border: 1px solid $grey;
	background-color: $white;
	margin-right: ($tsu / 2);

	.c-filter__button[aria-current="true"] & {
		border: 1px solid var(--filter-highlight);
		background-color: var(--filter-highlight);
		color: var(--secondary-b);

		&::before {
			@include font-icon;
			content: $iconf-tick;
			font-size: 14px;
		}
	}
}


.c-filter__toggle-btn {
	@include button("medium");
	display: flex;
	white-space: nowrap;
	align-items: center;
	justify-content: center;
	border-radius: 2px;

	@include bp-below("medium") {
		width: 100%;
	}
}

.c-filter__toggle-icon {
	&::after {
		display: block;
		margin-left: $ssu;

		@include font-icon;
		content: $iconf-close;
		font-size: 24px;

		.c-filter__toggle-btn[aria-expanded="false"] & {
			content: $iconf-filter;
		}

		.c-filter__toggle-btn & {
			font-size: 16px;
		}
	}
}

.c-filter__toggle-mobile-wrapper {
	@include font-base-16;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: $ssu;

	@include bp("medium") {
		display: none;
	}
}

.c-filter__toggle-mobile {
	border: 0;
}

.c-filter__listing {
	@include bp-below("medium") {
		position: fixed;
		overflow: auto;
		top: 0;
		z-index: 3;
		height: 100%;
		width: 100%;
		background-color: $grey--light;
		left: 0;
		transform: rotateY(0);
		transition: .3s;
		transform-style: preserve-3d;
		padding: 0 $ssu;
	}

	// Different default states, depending on breakpoint,
	// if aria-expanded attribute is absent
	@include bp-below("medium") {
		display: none;
	}
	@include bp("medium") {
		display: block;
	}

	&[aria-expanded="true"] {
		display: block;
	}
	&[aria-expanded="false"] {
		display: none;

		@include bp-below("medium") {
			transform: rotateY( -90deg);
			transition: .3s;
			transform-style: preserve-3d;
			position: fixed;
			top: 0;
			visibility: hidden;
			opacity: 0;
			overflow: hidden;
			display: block;
		}
	}
}

.c-filter__listing-section {
	margin: $bsu 0;
}

.c-filter__listing-section-title {
	display: block;
	margin-bottom: $ssu;

	@include font-heading-18b;
}

.c-filter__item-list {
	@include list-reset;
	display: flex;
	margin-left: -$tsu;
	margin-top: -$tsu;
	flex-wrap: wrap;

	@supports (background-color: var(--filter-highlight)) {
		margin-left: 0;
		position: relative;

		&::before {
			content: "";
			position: absolute;
			top: $tsu;
			bottom: 0;
			left: 0;
			width: 2px;
			background-color: var(--filter-highlight);
		}
	}
}

.c-filter__item-list--links {
	@include bp-below($filter-bp) {
		display: none !important;
	}
}
.c-filter__item-list--checkboxes,
.c-filter__checkboxes-submit {
	@include bp($filter-bp) {
		display: none !important;
	}
}

.c-filter__item {
	max-width: 100%;
}

.c-filter__item-checkbox {
	@include fake-input;
	&:focus-visible ~ .c-filter__item-button {
		@include focus-outline;
	}

	// Replicate theme action classes with respect to the button's
	// hover state and the checkbox's focus and checked states
	~ .c-filter__item-button {
		background-color: $primary-b;
		color: $primary-a;
		background-color: var(--primary-b);
		color: var(--primary-a);
	}
	// ~ .c-filter__item-button:hover,
	// &:focus-visible ~ .c-filter__item-button {}
	&:checked {
		~ .c-filter__item-button {
			background-color: $primary-a;
			color: $primary-b;
			background-color: var(--primary-a);
			color: var(--primary-b);
		}
		// ~ .c-filter__item-button:hover,
		// &:focus-visible ~ .c-filter__item-button {}
	}
}

.c-filter__item-button {
	@include button-reset;

	display: block;
	padding: 0 $ssu;
	border-radius: 40px;

	@include font-base-16m;
	height: 40px;
	line-height: 40px;
	white-space: nowrap;

	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;

	@include link-transition;
}

.c-filter__item-button--reset {
	background-color: $primary-b;
	color: $primary-a;
	background-color: var(--primary-b);
	color: var(--primary-a);
	// &:hover,
	// &:focus-visible {}

	&[aria-current="true"] {
		background-color: $primary-a;
		color: $primary-b;
		background-color: var(--primary-a);
		color: var(--primary-b);
		// &:hover,
		// &:focus-visible {}
	}
}

.c-filter__checkboxes-submit {
	@include button("large");

	margin-bottom: $bsu;
}

/////////////
// Summary //
/////////////
.c-filter__summary {
	@include bp-below($filter-bp) {
		margin-top: $ssu;
		max-width: 100vw;
	}
	@include bp($filter-bp) {
		margin-top: $bsu;
	}
}

.c-filter__summary-layout {
	@include layout-wrapper;

	@include bp-below("medium") {
		margin-left: -$tsu;
		margin-top: -$tsu;
		@include clearfix;
	}
	@include bp("medium") {
		display: flex;
		align-items: flex-start;
	}
}

.c-filter__summary-title {
	@include font-heading-18b;

	margin-right: $tsu;
	margin-top: 2px; // Offset to align with summary buttons

	flex-shrink: 0;

	@include bp-below("medium") {
		float: left;
		padding-left: $tsu;
		padding-top: $tsu;
	}
}

.c-filter__summary-list {
	@include list-reset;
	@include bp("medium") {
		display: flex;
		flex-wrap: wrap;

		margin-left: -$tsu;
		margin-top: -$tsu;
	}

}

.c-filter__summary-item {
	@include bp-below("medium") {
		float: left;
		max-width: 100%;
	}

	padding-left: $tsu;
	padding-top: $tsu;
}

.c-filter__summary-button {
	display: block;

	white-space: nowrap;
	@include font-base-12;

	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;

	border-radius: 20px;
	padding: $tsu ($tsu * 1.5);

	&::after {
		@include font-icon;
		content: $iconf-close;
		color: $grey--dark;

		display: inline-block;
		vertical-align: middle;
		margin-left: $tsu;
	}

	@include link-transition;
	&:hover,
	&:focus-visible {
		&::after {
			color: inherit;
		}
	}
}

.c-filter__summary {
	// Extra level for specificity to override base theme
	.c-filter__summary-button--reset {
		box-shadow: inset 0 0 0 1px var(--filter-highlight);
		&:hover,
		&:focus {
			background-color: var(--filter-highlight);
			color: var(--secondary-b);
		}
		&::after {
			display: none;
		}
	}
}
