$news-bp: "medium";

.c-projects-listing {
	@include control;
	margin-bottom: 0;
}
// Second block to ensure order is maintained when compiling
.c-projects-listing {
	margin-top: 0;

	@include bp($news-bp) {
		background-image: url("/content/images/interface/bg/white-circles-pattern-quarter-right.svg");
		background-position: bottom right;
		background-repeat: no-repeat;
	}
}

.c-projects-listing__layout {
	@include layout-wrapper;
}

.c-projects-listing__header {
	display: block;
	padding: $bsu 0 $bsu;

	@include bp("large") {
		padding: $hsu 0 $bsu;
	}

	background: $grey--light;
}

.c-projects-listing__header-layout {
	@include layout-wrapper;
}

.c-projects-listing__heading {
	@include font-heading-28b;

	@include bp("large") {
		@include font-heading-52b;
	}
}

.c-projects-listing__introduction {
	@include font-base-16;
	width: 100%;

	@include bp("medium") {
		@include font-base-18;
		width: 50%;
		padding-top: $bsu;
	}


	a {
		@include link-brand;
		@include font-base-16sb;
		@include link-transition;

		@include bp("medium") {
			@include font-base-18sb;
		}
	}
}

//////////
// LIST //
//////////

.c-projects-listing__list-background {
	position: relative;

	&:not(.c-project--grey-background) {
		@include control;
		background: $white;
	}
	&.c-project--grey-background {
		@include control("dark grey");
		background: $grey--light;
	}


	.c-content-type-projectlistingpage & {
		margin-top: 0 !important;
		padding: 0;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: -1px; // Ensure no rounding/anti-aliasing issues can occur
			height: 101px;
			background: $grey--light;
		}
	}
}

.c-projects-listing__list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;
	margin-left: -$ssu;
	margin-top: -$ssu;
}

@supports (display: grid) {
	.c-projects-listing__list {
		display: grid;
		margin: 0;
		grid-gap: $ssu;
		grid-template-columns: 100%;

		@include bp("medium") {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		@include bp("large") {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
	}
}

/////////////////
// Project Pod //
////////////////

.c-projects__header {
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin-bottom: $bsu;
}

.c-projects__heading {
	@include control__heading;
	margin-bottom: 0;
}

.c-projects__all-link-wrapper {
	display: flex;
	z-index: 1;

	@include bp-below($news-bp) {
		margin-top: $bsu;
		justify-content: center;
	}

	@include bp($news-bp) {
		justify-content: flex-end;
		// Adjust for line height, to align bottom with heading
		margin-bottom: 3px;
	}

	&:first-child:last-child {
		width: 100%;
	}
}

.c-projects__all-link {
	display: flex;
	align-items: center;

	@include font-base-20sb;

	&::after {
		@include font-icon;
		content: $iconf-arrow-right;
		padding-left: 0.5em;
		color: var(--secondary-a);
	}
}


.c-project-pod {
	margin-bottom: $bsu;
}

.c-project-pod__figure {
	position: relative;
}

.c-project-pod__region {
	@include font-base-12-tall;
	position: absolute;
	top: $ssu;
	left: $ssu;
	z-index: 1;
}

.c-project-pod__category {
	@include font-base-12sbu;
	position: absolute;
	top: 0;
	right: 0;
	padding: 3px 25px;
	z-index:1;
}

.c-project-pod__content {
	padding: ( $ssu * 1.5) 0;
}

.c-project-pod__title {
	@include font-heading-24b;
	margin-bottom: $ssu;

	@include bp("large") {
		@include font-heading-28b;
	}
}

.c-project-pod__body {
	@include font-base-16;
}

.c-project-pod__link {
	@include font-base-20sb;
	display: flex;
	align-items: center;
}

.c-project-pod__link-icon {
	display: inline-block;
	margin-left: $tsu;
}