.c-impact-numbers {
	@include control("dark grey");

	// When it's following a Pods control
	.c-pods + & {
		--impact-numbers-overlap: 1.5;

		margin-top: -$lsu * 1.5;
		padding-top: $lsu * 1.5;

		margin-top: calc(-1 * var(--impact-numbers-overlap) * var(--control-gap-top));
		padding-top: calc(var(--impact-numbers-overlap) * var(--control-gap-top));
	}
}

.c-impact-numbers__layout {
	@include layout-wrapper;
}

.c-impact-numbers__heading {
	@include control__heading;
	margin-bottom: $lsu;
}

.c-impact-numbers__list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;

	margin-left: -$bsu;
	margin-top: -$bsu;
}

.c-impact-numbers__item {
	padding-left: $bsu;
	padding-top: $bsu;

	width: 100%;
	@include bp("medium") {
		width: 50%;
	}
	@include bp("extra-large") {
		width: 25%;
	}
}

.c-impact-numbers__item-wrapper {
	display: block;

	position: relative;
}

a.c-impact-numbers__item-wrapper {
	@media (hover: hover) {
		padding-bottom: 40px + $ssu;

		&::after {
			@include font-icon;
			content: $iconf-arrow-right;

			position: absolute;
			right: 0;
			bottom: 0;

			font-size: 40px;
			line-height: 1;

			opacity: 0;
			transform: translateX(-50%);

			@include link-transition;
		}

		&:hover,
		&:focus {
			&::after {
				opacity: 1;
				transform: none;
			}
		}
	}
}

.c-impact-numbers__item-number {
	display: block;
	margin-bottom: $ssu;

	@include font-highlights-68;
	@include bp("medium") {
		@include font-highlights-100;
	}
}

.c-impact-numbers__item-name {
	display: block;
	margin-bottom: $bsu;

	@include font-base-20mu;
}

.c-impact-numbers__item-text {
	display: block;
	margin: 0;

	@include font-base-16;
}
