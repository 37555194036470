// COMPONENT.CED-LANDING-NAV

$ced-landing-nav-bp: "large";

.c-ced-landing-nav {
	@include control("grey");

	@include bp($ced-landing-nav-bp) {
		background-image: url("/content/images/interface/bg/white-circles-pattern-half-left.svg");
		background-repeat: no-repeat;
		background-position: right;
	}
}

.c-ced-landing-nav__layout {
	@include layout-wrapper;
}

.c-ced-landing-nav__opening-phrase {
	@include bp-below($ced-landing-nav-bp) {
		@include font-heading-20b;
	}
	@include bp($ced-landing-nav-bp) {
		@include font-heading-24b;
	}

	color: var(--supporting-2-a);
}

//////////////
// PATHWAYS //
//////////////

.c-ced-landing-nav__pathway {
	padding-top: $bsu;
	padding-bottom: $bsu;
	border-bottom: 2px solid var(--supporting-2-a);
}

.c-ced-landing-nav__pathway-title {
	display: block;

	@include bp-below($ced-landing-nav-bp) {
		@include font-heading-20b;
	}
	@include bp($ced-landing-nav-bp) {
		@include font-heading-36b;
	}

	display: flex;
	justify-content: space-between;
	&::after {
		@include font-icon;
		content: $iconf-plus;
		.c-ced-landing-nav__pathway[aria-expanded="true"] & {
			content: $iconf-minus;
		}

		display: inline-block;
		vertical-align: middle;
		margin-left: $ssu;

		color: var(--supporting-2-a);
	}

	cursor: pointer;
}

.c-ced-landing-nav__pathway-body {
	margin-top: $bsu;

	display: none;
	.c-ced-landing-nav__pathway[aria-expanded="true"] & {
		display: block;
	}
}

.c-ced-landing-nav__pathway-child-list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;

	@include bp-below($ced-landing-nav-bp) {
		margin-left: -$ssu;
		margin-top: -$ssu;
	}
	@include bp($ced-landing-nav-bp) {
		margin-left: -$bsu;
		margin-top: -$bsu;
	}
}

@mixin ced-landing-nav__pathway-child--open {
	.c-ced-landing-nav__pathway-child-title {
		color: var(--supporting-2-a);
	}

	.c-ced-landing-nav__pathway-child-body {
		@media (hover: hover) {
			visibility: visible;
		}
		@media (hover: none) {
			display: block;
		}
	}
}

.c-ced-landing-nav__pathway-child-item {
	flex: 1 1 0;
	width: 0;

	@include bp-below("small") {
		flex: 0 1 auto;
		width: 100%;
	}
	@include bp-below("medium") {
		@include bp("small") {
			&:first-child:nth-last-child(n+2) {
				&, & ~ .c-ced-landing-nav__pathway-child-item {
					flex: 0 1 auto;
					width: percentage((1 / 2));
				}
			}
		}
	}
	@include bp-below($ced-landing-nav-bp) {
		@include bp("medium") {
			&:first-child:nth-last-child(n+3) {
				&, & ~ .c-ced-landing-nav__pathway-child-item {
					flex: 0 1 auto;
					width: percentage((1 / 3));
				}
			}
		}
	}
	@include bp("large") {
		&:first-child:nth-last-child(n+6) {
			&, & ~ .c-ced-landing-nav__pathway-child-item {
				flex: 0 1 auto;
				width: percentage((1 / 6));
			}
		}
	}

	@include bp-below($ced-landing-nav-bp) {
		padding-left: $ssu;
		padding-top: $ssu;
	}
	@include bp($ced-landing-nav-bp) {
		padding-left: $bsu;
		padding-top: $bsu;
	}
}

.c-ced-landing-nav__pathway-child {
	display: flex;
	flex-direction: column;

	height: 100%;

	@media (hover: hover) {
		&:hover,
		&:focus {
			@include ced-landing-nav__pathway-child--open;
		}
		// Separate rule in case browser doesn't support :focus-within
		&:focus-within {
			@include ced-landing-nav__pathway-child--open;
		}
	}
	@media (hover: none) {
		&[aria-expanded="true"] {
			@include ced-landing-nav__pathway-child--open;
		}
	}

	color: inherit;
}

.c-ced-landing-nav__pathway-child-title {
	flex-grow: 1;
	display: flex;
	justify-content: space-between;

	@include bp-below("extra-large") {
		@include font-heading-20b;
	}
	@include bp("extra-large") {
		@include font-heading-28b;
	}
}
// Second block to preserve order despite breakpoints
.c-ced-landing-nav__pathway-child-title {
	line-height: ((30 / 28));

	@include link-transition;

	@media (hover: none) {
		color: var(--supporting-2-a);

		&::after {
			@include font-icon;
			content: $iconf-plus;
			.c-ced-landing-nav__pathway-child[aria-expanded="true"] & {
				content: $iconf-minus;
			}

			display: inline-block;
			padding-top: 4px;
			padding-left: $tsu;
		}
	}
}
.c-ced-landing-nav__pathway-child-title--touch {
	@media (hover: hover) {
		display: none;
	}
}
.c-ced-landing-nav__pathway-child-title--hover {
	@media (hover: none) {
		display: none;
	}
}

.c-ced-landing-nav__pathway-child-body {
	margin-top: $ssu;

	@media (hover: hover) {
		visibility: hidden;
	}
	@media (hover: none) {
		display: none;
	}

	@include bp-below("small") {
		padding-bottom: $ssu;
		border-bottom: 2px solid $grey--light;
	}
}

.c-ced-landing-nav__pathway-child-text {
	@include font-base-16;

	line-height: ((22 / 16));
}

.c-ced-landing-nav__pathway-child-link {
	display: block;
	margin-top: $ssu;

	@include font-base-14sb;
	line-height: ((20 / 14));

	color: var(--supporting-2-a);

	&::after {
		@include font-icon;
		content: $iconf-arrow-right;

		display: inline-block;
		vertical-align: middle;
		margin-left: $tsu;
	}
}

////////////
// SEARCH //
////////////

.c-ced-landing-nav__search {
	/*padding: $bsu 0 0;
	@include bp($ced-landing-nav-bp) {
		padding: $lsu $lsu 0;
	}*/
}

.c-ced-landing-nav__search-field {
	display: flex;
	padding: 0 $tsu 0 $bsu;

	border-radius: 75px;
	height: 75px;
	line-height: 75px;
/*	overflow: hidden;*/

	box-shadow: inset 0 0 0 2px currentColor;

	&:focus-within {
		box-shadow: inset 0 0 0 2px var(--supporting-2-a);
	}
}

@supports (box-shadow: inset 0 0 0 2px var(--supporting-2-a)) {
	// Fake selector ensures this rule only applies
	// on browsers that support :focus-within
	.fake-selector:focus-within,
	.c-ced-landing-nav__search-input:focus,
	.c-ced-landing-nav__search-button:focus:not(:focus-visible) {
		outline: 0;
	}
}

.c-ced-landing-nav__search-input {
	border: none;
	padding: 0 $tsu;
	margin-left: -$tsu;
	border-radius: 0;
	background: transparent;

	flex-grow: 1;

	@include bp-below($ced-landing-nav-bp) {
		@include font-base-14mu;
	}
	@include bp($ced-landing-nav-bp) {
		@include font-base-20mu;
	}

	@include placeholder-text($brand);
	@include placeholder-text(var(--primary-a));

	&::-webkit-search-cancel-button {
		display: none;
	}
	&::ms-clear {
		display: none;
		width: 0;
		height: 0;
	}
}

.c-ced-landing-nav__search-button {
	@include button-reset;

	@include bp-below($ced-landing-nav-bp) {
		font-size: 24px;
	}
	@include bp($ced-landing-nav-bp) {
		font-size: 32px;
	}
	width: 75px;

	&::before {
		@include font-icon;
		content: $iconf-search;
	}

	&:hover,
	&:focus {
		color: var(--supporting-2-a);
	}
}

.c-ced-landing-nav__search-suggestions {
	display: block;
	padding: $tsu $bsu 0;

	@include font-base-14;
	font-style: italic;

	a {
		color: inherit;

		text-decoration-line: underline;
		text-decoration-color: var(--supporting-2-a);

		&:hover,
		&:focus {
			color: var(--supporting-2-a);
		}
	}
}
