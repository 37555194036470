﻿// MACRO.MAP

@use "sass:math";

.m-map {
	@include body-text__figure;
}

.m-map__embed {
	@include embed;

	padding-bottom: percentage((2 / 3));
}
