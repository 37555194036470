﻿// CONTROL.SHARE-PAGE

.c-share-page {
	@include control;
}

.c-share-page__layout {
	@include layout-wrapper;
}

.c-share-page__body {
	position: relative;
	padding: $ssu $ssu $bsu $ssu;
	background-color: $grey--lightest;

	.o-layout-main &,
	.l-page__main-controls &,
	.l-page__secondary-controls & {
		display: block;

		@include bp("large") {
			@include flex-extend;
			align-items: flex-start;
			padding: $ssu $bsu;
			align-items: center;
		}
	}
}

.c-share-page__text-content {
	@include bp("large") {
		flex-grow: 1;

		display: flex;
		align-items: center;
	}
}

.c-share-page__body-wrapper {
	@include bp-below("large") {
		margin-bottom: $bsu;
	}

	.o-layout-main &,
	.l-page__main-controls &,
	.l-page__secondary-controls & {
		@include bp("medium") {
			flex: 1 1 auto;
			margin-bottom: $ssu;
		}

		@include bp("large") {
			margin-bottom: 0;
			padding-right: $bsu;
		}
	}

	> *:last-child {
		margin-bottom: 0;
	}
}

.c-share-page__actions {
	text-align: center;

	@include bp("large") {
		min-width: 280px;
		text-align: right;
	}
}

.c-share-page__heading {
	@include font-heading-24b;
	margin-bottom: $ssu;
	color: $body-color;
	text-align: center;

	@include bp("large") {
		text-align: left;
	}
}

.c-share-page__text {
	@include font-base-16;
	margin-bottom: 2em;
	color: $body-color;
}

.c-share-page__link {
	@include button("small");

	@include bp("medium") {
		white-space: nowrap;
	}
}

.c-share-page__link-icon {
	@include icon-wrapper;
	@include link-transition;
	@include square(19px);

	position: relative;
	top: 3px;
	display: inline-block;
	margin-right: 5px;
}