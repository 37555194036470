// COMPONENT.ACCOMMODATION

.c-accommodation {}

.c-accommodation__layout-wrapper {
	@include layout-wrapper;
}

.c-accommodation__feature-title {
	@include control__heading;
}

.c-accommodation__feature-items {
	@include list-reset;

	margin-bottom: $bsu;
	display:flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.c-accommodation__feature-item {
	width: 50%;
	display: flex;
	align-items: center;
	margin-bottom: $bsu;
	padding-right: $bsu;
}

.c-accommodation__feature-item-img {
	width: 100%;
	max-width: 50px;
	margin-right: $bsu;
}

//tariff-panel

.c-tariff-panel {
	margin: 0 auto $lsu;
	max-width: 320px;
	padding: $bsu;

	@include bp("medium") {
		flex-shrink: 0;
		width: 320px;
	}
}

.c-tariff-panel__price-label {
	margin-bottom: $ssu;
}

.c-tariff-panel__price {
	margin-bottom: $bsu;
}

.c-tariff-panel__booking-widget {
	margin-bottom: $bsu;
}

.c-tariff-panel__button {
	@include button;

	display: block;
	width: 100%;
}
